import { keepPreviousData, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { meanBy } from "lodash";
import { useMemo } from "react";
import { useRecordContext } from "react-admin";
import { Chart } from "../../../layout/Chart";
import { dataProvider } from "../../../providers/data";

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface ConsumptionGraphProps {
  startDate?: Date;
  endDate?: Date;
}

export const ConsumptionProfileGraph = ({
  startDate,
  endDate,
}: ConsumptionGraphProps) => {
  const startDateString = dayjs(startDate).format("YYYY-MM-DD");
  const endDateString = dayjs(endDate).format("YYYY-MM-DD");

  const record = useRecordContext();
  const { data: result } = useQuery({
    queryFn: () =>
      dataProvider.getDemandResponseEntityConsumption(record?.id as string, {
        startDate: startDateString,
        endDate: endDateString,
      }),
    queryKey: [
      "demand-response-entities/consumption-profile",
      record?.id,
      startDateString,
      endDateString,
    ],
    enabled: !!record,
    // The graph only changes every day. We don't need to refetch it often.
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false, // prevent refetch on focus which will reset selected dates
    gcTime: 3600 * 1000, // 1h
    staleTime: 3600 * 1000, // 1h
  });
  const data = result?.data;

  const series = useMemo(() => {
    return (
      data &&
      ([
        ...data.map(({ day, data }) => ({
          name: day,
          type: "spline",
          visible: false,
          data: data.map((point) => ({
            x: point.timestamp,
            y: point.value,
          })),
        })),
        {
          name: "Average of all selected dates",
          type: "spline",
          visible: true,
          data: data[0]?.data?.map((sample, i) => ({
            x: sample.timestamp,
            y: meanBy(data, (d) => d.data[i].value),
          })),
        },
      ] as any)
    );
  }, [data]);

  return (
    <Chart
      isLoading={!data}
      options={{
        ...chartOptions,
        subtitle: {
          text: data && `Last Update: ${dayjs().to(result.lastUpdateDate)}`,
        },
        series,
      }}
    />
  );
};

const chartOptions: Highcharts.Options = {
  chart: {
    type: "line",
  },
  title: {
    text: "Profil de puissance",
  },
  plotOptions: {
    spline: {
      lineWidth: 1,
      states: {
        hover: {
          lineWidth: 3,
        },
      },
      marker: {
        enabled: false,
      },
    },
  },
  xAxis: {
    crosshair: true,
    type: "datetime",
    tickPixelInterval: 150,
  },
  yAxis: {
    title: {
      text: "kW",
    },
    labels: {
      formatter() {
        return ((this.value as number) / 1000).toFixed(0);
      },
    },
  },
};
