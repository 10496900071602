import { Datagrid, DateField, List, ListActions, TextField } from "react-admin";

export const EDRList = () => (
  <List perPage={100} actions={<ListActions hasCreate />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" label="name" />
      <DateField source="createdAt" label="creation date" />
    </Datagrid>
  </List>
);
