import { useListContext } from "react-admin";
import { DateRangePicker } from "../resources/devices/show/tabs/data/DateRangePicker";

interface DateRangeFilterProps {
  source: string;
  label?: string;
}

export const DateRangeFilter = ({ source, label }: DateRangeFilterProps) => {
  const { displayedFilters, filterValues, setFilters } = useListContext();

  const value = filterValues[source];
  return (
    <DateRangePicker
      label={label}
      range={{
        startDate: value?.$gte ? new Date(value?.$gte) : undefined,
        endDate: value?.$lte ? new Date(value?.$lte) : undefined,
      }}
      onChange={(range) => {
        setFilters(
          {
            ...filterValues,
            [source]: { $gte: range.startDate, $lte: range.endDate },
          },
          displayedFilters,
        );
      }}
    />
  );
};
