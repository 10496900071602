import { Box, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";
import dayjs from "dayjs";
import { chain, flatMap, partition, range, sortBy } from "lodash";
import { useRecordContext } from "react-admin";
import type { AsyncReturnType, Except } from "type-fest";
import { dataProvider } from "../../../../../../../providers/data";
import type { Device } from "../../../../../types";
import { HistoryItemType, historyItemColor } from "../../../history";
import { useDevicePeriods } from "../../useReferencePeriod";

export const useHistoryTimeline = (
  options: Except<
    UseQueryOptions<
      AsyncReturnType<typeof dataProvider.getDeviceHistory>,
      unknown,
      AsyncReturnType<typeof dataProvider.getDeviceHistory>,
      any
    >,
    "queryFn" | "queryKey"
  >,
) => {
  const device = useRecordContext<Device>();
  const { reference } = useDevicePeriods();

  const { data: history } = useQuery({
    queryKey: ["devices", "history", { id: device?.id }],
    queryFn: () => dataProvider.getDeviceHistory(device?.id ?? ""),
    ...options,
    enabled: Boolean(options.enabled) && Boolean(device),
  });
  if (!history || history.length === 0) {
    return {};
  }

  const [rangeHistoryItems, historyItems] = partition(
    history,
    (item) =>
      item.type === HistoryItemType.ALERT ||
      item.type === HistoryItemType.ABSENCE,
  );

  const historyByDay = chain([
    ...historyItems,
    ...prepareRangeHistoryData(rangeHistoryItems),
    ...prepareObservationData(reference),
  ])
    .groupBy((item) => dayjs(item.date).format("YYYY-MM-DD"))
    .mapValues((items, day) => {
      const sortedItems = sortBy(
        items,
        (item) =>
          // @ts-expect-error opacity can be undefined but ?? operator is not used correctly. To fix without affecting sort.
          // eslint-disable-next-line no-constant-binary-expression
          priority[item.type as HistoryItemType] + (1 - item.opacity ?? 1),
      );

      return {
        // @ts-expect-error item type was not checked
        color: sortedItems[0].color ?? historyItemColor[sortedItems[0].type],
        tooltip: (
          <>
            <Typography variant="caption">{day}</Typography>
            {sortedItems.map((item, i) => (
              <Box
                key={i}
                sx={{
                  borderLeft: `solid 5px ${
                    // @ts-expect-error item type was not checked
                    item.color ?? historyItemColor[item.type]
                  }`,
                  paddingLeft: 1,
                  marginY: 1,
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  {/* @ts-expect-error item type was not checked */}
                  {item.type} {item.title}
                </Typography>
                {/* @ts-expect-error item type was not checked */}
                <Typography variant="caption">{item.content}</Typography>
              </Box>
            ))}
          </>
        ),
      };
    })
    .value();

  return historyByDay;
};

const priority = {
  [HistoryItemType.TICKET]: 1,
  [HistoryItemType.PRODUCTION]: 2,
  [HistoryItemType.INTERVENTION]: 3,
  [HistoryItemType.CONFIG]: 4,
  [HistoryItemType.ALERT]: 5,
  [HistoryItemType.ABSENCE]: 6,
  [HistoryItemType.BOOST]: 6,
  OBSERVATION: 7,
};

const prepareRangeHistoryData = (
  rangeHistory: AsyncReturnType<typeof dataProvider.getDeviceHistory>,
) => {
  return flatMap(rangeHistory, (item) => {
    return range(
      dayjs(item.endDate)
        .endOf("day")
        .diff(dayjs(item.date).startOf("day"), "days"),
    ).map((i) => ({
      ...item,
      date: dayjs(item.date).add(i, "days").format(),
      opacity: Math.max(0.9 ** i, 0.1),
      color: `rgba(${
        item.type === HistoryItemType.ALERT ? "244, 67, 54" : "84, 164, 224"
      }, ${Math.max(0.9 ** i, 0.1).toFixed(2)})`,
    }));
  });
};

const prepareObservationData = (reference: {
  startDate?: Date;
  endDate?: Date;
}) => {
  if (!reference.startDate) {
    return [];
  }
  const start = dayjs(reference.startDate);
  return range(
    dayjs(reference.endDate).endOf("day").diff(start.startOf("day"), "days"),
  ).map((i) => ({
    type: "OBSERVATION",
    date: start.add(i, "days").format(),
    color: green[200],
  }));
};
