import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useStore } from "react-admin";
import { useDateRangeFilter } from "../../../../../../filters/useDateRangeFilter";
import { DateRangeFilter } from "../DateRangeFilter";
import { DeviceTimeline } from "./Timeline";
import { Track, TrackSelector, useTimelineData } from "./tracks";

export const DeviceTimeNavigator = () => {
  const [selectedTrack, onTrackChange] = useStore(
    "timeline.track",
    Track.HISTORY,
  );
  const data = useTimelineData(selectedTrack);
  const { dateRange, setDateRange } = useDateRangeFilter();

  return (
    <>
      {selectedTrack === Track.NONE ? (
        <Box>
          <DateRangeFilter />
        </Box>
      ) : (
        <DeviceTimeline
          data={data}
          selectedRange={{
            start: dayjs(dateRange.startDate).format("YYYY-MM-DD"),
            end: dayjs(dateRange.endDate).format("YYYY-MM-DD"),
          }}
          onTimeRangeSelection={({ start, end }) =>
            setDateRange({
              startDate: new Date(start),
              endDate: dayjs(end).endOf("day").toDate(),
            })
          }
        />
      )}

      <TrackSelector value={selectedTrack} onChange={onTrackChange} />
    </>
  );
};
