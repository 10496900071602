import { Box } from "@mui/material";
import type { ReactNode } from "react";
import { Login } from "react-admin";
import { ElaxLogo } from "../icons/ElaxLogo";

interface NoLayoutCardProps {
  children: ReactNode;
}

export const NoLayoutCard = ({ children }: NoLayoutCardProps) => {
  return (
    <Login sx={{ backgroundImage: "none" }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom={2}
        paddingX={2}
      >
        <ElaxLogo style={{ marginBottom: 10 }} />
        {children}
      </Box>
    </Login>
  );
};
