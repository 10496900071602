import { Grid } from "@mui/material";
import { NoWheelNumberInput } from "../../../fields/NumberInput";
import { integer } from "../../../validations/integer";

interface StaticModeParams {
  setPoint: number;
  hysteresis: number;
  setPoint2?: number;
  hysteresis2?: number;
}

export const StaticModeParameters = ({
  source,
  majorHWVersion,
}: {
  source: string;
  majorHWVersion?: 1 | 2 | 3;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NoWheelNumberInput
          source={`${source}.setPoint`}
          label={`SetPoint${majorHWVersion === 3 ? " 1" : ""} [1°C]`}
          fullWidth
          defaultValue={80}
          step={1}
          validate={integer}
        />
      </Grid>
      <Grid item xs={6}>
        <NoWheelNumberInput
          source={`${source}.hysteresis`}
          label={`Hysteresis${majorHWVersion === 3 ? " 1" : ""} [0.1°C]`}
          fullWidth
          defaultValue={20}
          step={1}
          validate={integer}
        />
      </Grid>
      {majorHWVersion === 3 ? (
        <>
          <Grid item xs={6}>
            <NoWheelNumberInput
              source={`${source}.setPoint2`}
              label="SetPoint 2 [1°C]"
              fullWidth
              defaultValue={80}
              step={1}
              validate={integer}
            />
          </Grid>
          <Grid item xs={6}>
            <NoWheelNumberInput
              source={`${source}.hysteresis2`}
              label="Hysteresis 2 [0.1°C]"
              fullWidth
              defaultValue={20}
              step={1}
              max={256}
              validate={integer}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export const SetPointParamsToString = (
  { setPoint, hysteresis, setPoint2, hysteresis2 }: StaticModeParams,
  majorHWVersion?: 1 | 2 | 3,
) =>
  `${setPoint ?? 80}°C ±${((hysteresis ?? 20) / 10).toFixed(1)}°C${
    majorHWVersion === 3
      ? ` / ${setPoint2 ?? 80}°C ±${((hysteresis2 ?? 20) / 10).toFixed(1)}°C`
      : ""
  }`;
