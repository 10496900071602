import EmailIcon from "@mui/icons-material/AlternateEmail";
import SuccessIcon from "@mui/icons-material/Check";
import FailureIcon from "@mui/icons-material/Error";
import LetterIcon from "@mui/icons-material/HistoryEdu";
import PendingIcon from "@mui/icons-material/Pending";
import SmsIcon from "@mui/icons-material/Sms";
import NotPossibleIcon from "@mui/icons-material/Warning";
import type { Color, SvgIconTypeMap } from "@mui/material";
import type { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  amber,
  blue,
  brown,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";

export interface Communication {
  id: string;
  context: {
    endUserId?: string;
    residenceId?: string;
    projectId?: string;
    housingId?: string;
    organizationId?: string;
    subscriptionId?: string;
    maintenanceProviderId?: string;
    interventionId?: string;
  };
  topic:
    | ProjectCommunicationTopic
    | OrganizationCommunicationTopic
    | ResidentCommunicationTopic
    | MaintenanceProviderCommunicationTopic;
  medium: CommunicationMedium;
  date: Date;
  externalId?: string;
  status: CommunicationStatus;
  statusDetails?: string;
}

export enum CommunicationStatus {
  NOT_POSSIBLE = "not possible",
  PENDING = "pending",
  SUCCESS = "success",
  FAILURE = "failure",
}

export enum CommunicationMedium {
  EMAIL = "email",
  LETTER = "letter",
  SMS = "sms",
}

export const communicationMediumConfigs: Record<
  CommunicationMedium,
  {
    color: Color;
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  }
> = {
  [CommunicationMedium.EMAIL]: { color: blue, label: "Email", icon: EmailIcon },
  [CommunicationMedium.LETTER]: {
    color: purple,
    label: "Courrier",
    icon: LetterIcon,
  },
  [CommunicationMedium.SMS]: { color: green, label: "SMS", icon: SmsIcon },
};

export const communicationStatusConfigs: Record<
  CommunicationStatus,
  {
    color: Color;
    label: string;
    icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  }
> = {
  [CommunicationStatus.SUCCESS]: {
    color: green,
    label: "Succès",
    icon: SuccessIcon,
  },
  [CommunicationStatus.FAILURE]: {
    color: red,
    label: "Échec",
    icon: FailureIcon,
  },
  [CommunicationStatus.PENDING]: {
    color: grey,
    label: "En cours",
    icon: PendingIcon,
  },
  [CommunicationStatus.NOT_POSSIBLE]: {
    color: orange,
    label: "Envoi impossible",
    icon: NotPossibleIcon,
  },
};

export enum ProjectCommunicationTopic {
  LANDLORD_SMART_TOGGLE = "LANDLORD_SMART_TOGGLE",
  AVOIDED_EMISSIONS = "AVOIDED_EMISSIONS",
  DEPLOYMENT_REVIEW = "DEPLOYMENT_REVIEW",
  DEPRECATED_DEPLOYMENT_START = "DEPLOYMENT_START",
  LIMESCALE_INFO = "LIMESCALE_INFO",
}

export enum OrganizationCommunicationTopic {
  UPSELL_INCENTIVE = "UPSELL_INCENTIVE",
  MONTHLY_SAVINGS = "MONTHLY_SAVINGS",
  SAVINGS_V2_RELEASE = "organization:savings-v2-release",
}

export enum ResidentCommunicationTopic {
  SMART_TOGGLE = "SMART_TOGGLE",
  SAVINGS = "resident:savings",
  SURVEY_REMINDER = "resident:survey-reminder",
  INSTALLATION_APPOINTMENT = "INSTALLATION_APPOINTMENT",
  INSTALLATION_APPOINTMENT_REMINDER = "INSTALLATION_APPOINTMENT_REMINDER",
  NPS = "NPS",
  NPS_REMINDER = "resident:nps-reminder",
  DEVICE_PRESENTATION = "DEVICE_PRESENTATION",
  SAVINGS_V2_RELEASE = "resident:savings-v2-release",
  BYPASS_ALERT = "resident:bypass-alert",
  COMFORT_CHECK = "COMFORT_CHECK",
  COMFORT_CHECK_REMINDER = "resident:comfort-check-reminder",
}

export enum MaintenanceProviderCommunicationTopic {
  INTERVENTION_CHANGED = "INTERVENTION_CHANGED",
  INTERVENTION_CANCELLED = "INTERVENTION_CANCELLED",
  INTERVENTIONS_REPORT = "INTERVENTIONS_REPORT",
  LAST_MINUTE_INTERVENTION = "LAST_MINUTE_INTERVENTION",
  INFO_FOR_MAINTENANCE_PROVIDER = "INFO_FOR_MAINTENANCE_PROVIDER",
}

export const communicationTopicConfigs: Record<
  | ProjectCommunicationTopic
  | OrganizationCommunicationTopic
  | ResidentCommunicationTopic
  | MaintenanceProviderCommunicationTopic,
  { color: Color; label: string }
> = {
  [ProjectCommunicationTopic.LANDLORD_SMART_TOGGLE]: {
    color: red,
    label: "Passage en pilotage",
  },
  [ProjectCommunicationTopic.AVOIDED_EMISSIONS]: {
    color: pink,
    label: "Émissions évitées",
  },
  [ProjectCommunicationTopic.DEPLOYMENT_REVIEW]: {
    color: purple,
    label: "Bilan de déploiement",
  },
  [ProjectCommunicationTopic.DEPRECATED_DEPLOYMENT_START]: {
    color: deepPurple,
    label: "Démarrage du déploiement",
  },
  [ProjectCommunicationTopic.LIMESCALE_INFO]: {
    color: indigo,
    label: "Calcairisation",
  },
  [OrganizationCommunicationTopic.UPSELL_INCENTIVE]: {
    color: blue,
    label: "Upsell",
  },
  [OrganizationCommunicationTopic.MONTHLY_SAVINGS]: {
    color: lightBlue,
    label: "Économies",
  },
  [OrganizationCommunicationTopic.SAVINGS_V2_RELEASE]: {
    color: lightBlue,
    label: "Économies v2 release",
  },
  [ResidentCommunicationTopic.SMART_TOGGLE]: {
    color: brown,
    label: "Passage en pilotage",
  },
  [ResidentCommunicationTopic.INSTALLATION_APPOINTMENT]: {
    color: green,
    label: "Prise de RDV",
  },
  [ResidentCommunicationTopic.INSTALLATION_APPOINTMENT_REMINDER]: {
    color: green,
    label: "Relance prise de RDV",
  },
  [ResidentCommunicationTopic.NPS]: { color: lime, label: "NPS" },
  [ResidentCommunicationTopic.NPS_REMINDER]: {
    color: lime,
    label: "Relance NPS",
  },
  [ResidentCommunicationTopic.SAVINGS]: {
    color: amber,
    label: "Économies",
  },
  [ResidentCommunicationTopic.SURVEY_REMINDER]: {
    color: amber,
    label: "Relance économies",
  },
  [ResidentCommunicationTopic.SAVINGS_V2_RELEASE]: {
    color: amber,
    label: "Économies v2 release",
  },
  [ResidentCommunicationTopic.DEVICE_PRESENTATION]: {
    color: orange,
    label: "Présentation boitier",
  },
  [MaintenanceProviderCommunicationTopic.INTERVENTION_CHANGED]: {
    color: deepOrange,
    label: "RDV modifié",
  },
  [MaintenanceProviderCommunicationTopic.INTERVENTION_CANCELLED]: {
    color: deepOrange,
    label: "RDV annulé",
  },
  [MaintenanceProviderCommunicationTopic.INTERVENTIONS_REPORT]: {
    color: brown,
    label: "Rapport d'interventions",
  },
  [MaintenanceProviderCommunicationTopic.LAST_MINUTE_INTERVENTION]: {
    color: deepOrange,
    label: "Intervention de dernière minute",
  },
  [MaintenanceProviderCommunicationTopic.INFO_FOR_MAINTENANCE_PROVIDER]: {
    color: brown,
    label: "Infos pour mainteneur",
  },
  [ResidentCommunicationTopic.BYPASS_ALERT]: {
    color: yellow,
    label: "Bypass rescue",
  },
  [ResidentCommunicationTopic.COMFORT_CHECK]: {
    color: teal,
    label: "Comfort check",
  },
  [ResidentCommunicationTopic.COMFORT_CHECK_REMINDER]: {
    color: teal,
    label: "Relance comfort check",
  },
};
