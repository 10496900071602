import { Datagrid, Pagination, ReferenceManyField } from "react-admin";
import { communicationFields } from "../../../communications/fields";

export function CommunicationTab() {
  return (
    <ReferenceManyField
      reference="communications"
      target="context.endUserId"
      pagination={<Pagination />}
    >
      <Datagrid bulkActionButtons={false}>
        {communicationFields.date}
        {communicationFields.topic}
        {communicationFields.medium}
        {communicationFields.status}
        {communicationFields.statusDetails}
        {communicationFields.project}
        {communicationFields.housing}
      </Datagrid>
    </ReferenceManyField>
  );
}
