import { FormControlLabel, Switch } from "@mui/material";
import { useRecordContext, useRefresh } from "react-admin";
import { useAxiosMutation } from "../../../../../providers/data-hooks/useAxiosQuery";
import type { Device } from "../../../types";

export function DisableDemandResponse() {
  const device = useRecordContext<Device>();
  const refresh = useRefresh();
  const mutation = useAxiosMutation<{ disabledDemandResponse: boolean }>(
    {
      url: `/devices/${device?.id}/disable-demand-response`,
      method: "PUT",
    },
    {
      onSettled: refresh,
    },
  );

  if (!device) return null;

  return (
    <FormControlLabel
      control={
        <Switch
          checked={device.disabledDemandResponse}
          disabled={mutation.isPending}
          onChange={(_, value) => {
            mutation.mutate({
              disabledDemandResponse: value,
            });
          }}
        />
      }
      label="Disable demand response"
    />
  );
}
