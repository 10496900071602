import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useState } from "react";
import { useGetList, useListContext, useUnselectAll } from "react-admin";
import { useNotifyAction } from "../../../actions/useNotifyAction";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";

export const EditDevicesButton = ({
  resource,
  label,
  source,
  icon,
}: {
  resource: "groups" | "device-tags";
  source: "group" | "tag";
  label: string;
  icon: React.ReactNode;
}) => {
  const { selectedIds } = useListContext();

  const unselectAll = useUnselectAll("devices");

  const [itemId, selectItem] = useState<string>("");
  const [action, selectAction] = useState("add");
  const { mutate: updateDevices } = useNotifyAction(
    () =>
      dataProvider.bulkUpdate(
        "devices",
        { id: selectedIds },
        {
          [`${action === "add" ? "+" : "-"}${source}`]: itemId,
        },
      ),
    {
      successMessage: "Done",
      errorPrefixMessage: `Failed to update ${label}`,
    },
  );

  return (
    <DialogActionMenuItem
      label={`Assign ${label}`}
      icon={icon}
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>{`Assign ${label}`}</DialogTitle>
          <DialogContent>
            <SelectItem
              resource={resource}
              label={label}
              value={itemId}
              action={action}
              onChange={(action, item) => {
                selectAction(action);
                selectItem(item);
              }}
            />
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              disabled={!itemId}
              onClick={(_, close) => {
                updateDevices();
                unselectAll();
                close();
              }}
            >
              {`Assign ${label}`}
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};

interface SelectGroupProps {
  resource: "groups" | "device-tags";
  label: string;
  value: string;
  action: string;
  onChange: (action: string, value: string) => void;
}

const SelectItem = ({
  label,
  resource,
  value,
  action,
  onChange,
}: SelectGroupProps) => {
  const { data: items } = useGetList(resource, {
    pagination: { perPage: 1000, page: 1 },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <InputLabel sx={{ marginLeft: 2 }}>{label}</InputLabel>
      <Box sx={{ display: "flex" }}>
        <FormControl fullWidth>
          <Select
            value={action}
            onChange={(event) => onChange(event.target.value, value)}
            sx={{ margin: 2 }}
          >
            <MenuItem value="add">Add</MenuItem>
            <MenuItem value="remove">Remove</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Select
            value={value}
            onChange={(event) => onChange(action, event.target.value)}
            sx={{ margin: 2 }}
          >
            {(items || []).map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.groupName ?? item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};
