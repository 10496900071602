import { getErrorMessage } from "@elax/utils";
import Close from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useState } from "react";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../layout/actions-menu";
import { useAxiosMutation } from "../providers/data-hooks/useAxiosQuery";

interface ImportActionProps {
  endpoint: string;
  title: string;
  icon: ReactNode;
  templateURL: string;
}

export function ImportAction({
  endpoint,
  title,
  icon,
  templateURL,
}: ImportActionProps) {
  const { mutate } = useImport(endpoint);
  const [fileURL, setFileURL] = useState<string>("");

  return (
    <DialogActionMenuItem
      label={title}
      icon={icon}
      dialog={
        <ActionDialog>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <TextField
              label="Google Spreadsheet URL"
              fullWidth
              value={fileURL}
              autoFocus
              onChange={(event) => {
                setFileURL(event.target.value);
              }}
              sx={{ minWidth: 300 }}
              margin="normal"
              helperText={
                <Typography variant="caption">
                  Follow{" "}
                  <a href={templateURL} target="_blank" rel="noreferrer">
                    this template
                  </a>
                </Typography>
              }
            />
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              onClick={() => mutate({ fileURL })}
            >
              Confirm
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
}

const useImport = (endpoint: string) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return useAxiosMutation<{ fileURL: string }>(
    { method: "PUT", url: endpoint },
    {
      onMutate: () => {
        enqueueSnackbar("Import started...", {
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          autoHideDuration: null,
          action: (
            <>
              <CircularProgress size={15} />
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeSnackbar()}
              >
                <Close fontSize="small" />
              </IconButton>
            </>
          ),
        });
      },
      onSuccess: () => {
        closeSnackbar();
        enqueueSnackbar("Import will be ready soon, check on Slack", {
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
          autoHideDuration: null,
          action: (
            <>
              <Button
                size="small"
                onClick={() => {
                  window.open(
                    "https://elaxenergie.slack.com/archives/C07PF4A12DV",
                  );
                  closeSnackbar();
                }}
              >
                OPEN
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeSnackbar()}
              >
                <Close fontSize="small" />
              </IconButton>
            </>
          ),
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          <Alert severity="error">{`Failed to import data: ${getErrorMessage(
            error,
          )}`}</Alert>,
        );
      },
    },
  );
};
