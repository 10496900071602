import { CircularProgress, Divider, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { partition } from "lodash";
import {
  Button,
  DateField,
  RecordContext,
  ReferenceOneField,
  TextField,
} from "react-admin";
import { ContractTypeField } from "../../fields/ContractTypeField";
import type { Device } from "../../types";

interface Result {
  status: string;
  message?: string;
}
interface ReportPilotChangeProps {
  results: Record<string, Result>;
  devices: Device[];
  retry: (device: Device) => void;
}

export const ReportPilotChange = ({
  results,
  devices,
  retry,
}: ReportPilotChangeProps) => {
  const [success, others] = partition(
    devices,
    (device) => results[device.id]?.status === "OK",
  );
  return (
    <>
      <Table aria-label="report" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Devices to process</TableCell>
            <TableCell>Contract Type</TableCell>
            <TableCell>Current Mode</TableCell>
            <TableCell>Last mode update</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {others.map((device) => (
            <DeviceRow
              key={device.id}
              device={device}
              result={results[device.id]}
              retry={() => retry(device)}
            />
          ))}
        </TableBody>
      </Table>
      <Divider />
      <Table aria-label="report" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Successfully modified devices</TableCell>
            <TableCell>Contract Type</TableCell>
            <TableCell>Current Mode</TableCell>
            <TableCell>Last mode update</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {success.map((device) => (
            <DeviceRow
              key={device.id}
              device={device}
              result={results[device.id]}
              retry={() => retry(device)}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

const DeviceRow = ({
  device,
  result,
  retry,
}: {
  device: Device;
  result: Result;
  retry: () => void;
}) => {
  return (
    <RecordContext.Provider value={device}>
      <TableRow
        sx={{
          backgroundColor:
            result?.status === "OK"
              ? "#8bc34a99"
              : result?.status === "error"
              ? "#f4433699"
              : undefined,
        }}
      >
        <TableCell>{device.name}</TableCell>
        <TableCell>
          <ContractTypeField />
        </TableCell>
        <TableCell>
          <ReferenceOneField
            reference="device-configs"
            target="deviceId"
            sort={{ field: "createdAt", order: "DESC" }}
            label="Pilot Mode"
            sortable={false}
          >
            <TextField source="mode" />
          </ReferenceOneField>
        </TableCell>
        <TableCell>
          <ReferenceOneField
            reference="device-configs"
            target="deviceId"
            sort={{ field: "createdAt", order: "DESC" }}
            label="Pilot Mode"
            sortable={false}
          >
            <DateField source="createdAt" showTime />
          </ReferenceOneField>
        </TableCell>
        <TableCell>
          <Status result={result} retry={retry} />
        </TableCell>
      </TableRow>
    </RecordContext.Provider>
  );
};

const Status = ({ result, retry }: { result: Result; retry: () => void }) => {
  if (!result) {
    return <Typography></Typography>;
  }
  if (result.status === "pending") {
    return (
      <Typography>
        <CircularProgress size={15} />
      </Typography>
    );
  }
  if (result.status === "error") {
    return (
      <>
        <Typography>Error: {result.message}</Typography>
        <Button label="Retry" onClick={retry} />
      </>
    );
  }

  return <Typography>{result.status}</Typography>;
};
