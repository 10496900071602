import { Divider } from "@mui/material";
import { useBulkActionFilter } from "../../../actions/useBulkActionFilter";
import { ActionsMenu } from "../../../layout/actions-menu";
import { DeviceTagIcon } from "../../device-tags";
import { GroupIcon } from "../../group";
import { ExportDeviceFrames } from "../show/actions/ExportDeviceFrames";
import { AssignFirmware } from "./AssignFirmware";
import { EditDevicesButton } from "./BulkEditDevices";
import { ChangePilotConfig } from "./edit-device-config";

export const DeviceBulkActionButtons = () => {
  const filter = useBulkActionFilter();
  return (
    <>
      <ActionsMenu>
        <ExportDeviceFrames filter={filter} />
        <Divider />
        <EditDevicesButton
          resource="groups"
          source="group"
          label="Groups"
          icon={<GroupIcon />}
        />
        <EditDevicesButton
          resource="device-tags"
          source="tag"
          label="Tags"
          icon={<DeviceTagIcon />}
        />
        <ChangePilotConfig filter={filter} />
        <AssignFirmware filter={filter} />
      </ActionsMenu>
    </>
  );
};
