import { Create, SimpleForm, TextInput } from "react-admin";

export const EDRCreate = () => (
  <Create redirect="list">
    <EditionForm />
  </Create>
);

const EditionForm = () => {
  return (
    <SimpleForm>
      <TextInput source="name" required={true} fullWidth />
    </SimpleForm>
  );
};
