import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { FilterButton, TopToolbar } from "react-admin";
import { ImportAction } from "../../../actions/ImportAction";
import { SelectColumnsButton } from "../../../layout/SelectColumns";
import { ActionsMenu, CreateAction } from "../../../layout/actions-menu";
import { interventionColumns } from "../List";
import { SyncKizeoButton } from "./SyncKizeo";

export const InterventionActions = () => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton
      preference="interventions.list.columns"
      columns={interventionColumns}
    />
    <ActionsMenu>
      <CreateAction />
      <SyncKizeoButton />
      <ImportAction
        endpoint="/interventions/import-appointments"
        icon={<CalendarMonthIcon />}
        title="Import appointments"
        templateURL="https://docs.google.com/spreadsheets/d/1BlZ9Q8blTXgkrkhhxEmAhqWs76vRdNUuEurOTysI1Kw"
      />
    </ActionsMenu>
  </TopToolbar>
);
