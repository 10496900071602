import { Grid } from "@mui/material";
import {
  ArrayInput,
  Create,
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { prepareFiles } from "../../providers/file-handler";
import type { MaintenanceProvider } from "./type";

const MaintenanceProviderEditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextInput source="name" fullWidth />
        </Grid>
        <Grid item sm={12}>
          <TextInput source="maintenanceSpreadsheetId" fullWidth />
        </Grid>
        <Grid item sm={12}>
          <ImageInput
            source="logo"
            label="Logo"
            maxSize={200_000}
            helperText="Max 200kb. Please use transparent PNG or JPEG with white background"
            accept={{ "image/*": [".png", ".jpg", ".jpeg"] }}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>
        <Grid item sm={12}>
          <ArrayInput source="notificationEmails.deployment" fullWidth>
            <SimpleFormIterator fullWidth>
              <TextInput source="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item sm={12}>
          <ArrayInput source="notificationEmails.maintenance" fullWidth>
            <SimpleFormIterator fullWidth>
              <TextInput source="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item sm={12}>
          <ArrayInput source="notificationEmails.interventions" fullWidth>
            <SimpleFormIterator fullWidth>
              <TextInput source="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </Grid>
        <Grid item sm={12}>
          <TextInput source="kizeoId" label="Kizeo ID" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const MaintenanceProviderEdit = () => (
  <Edit
    transform={prepareFiles<MaintenanceProvider>(["logo"])}
    redirect="list"
    mutationMode="pessimistic"
  >
    <MaintenanceProviderEditionForm />
  </Edit>
);

export const MaintenanceProviderCreate = () => (
  <Create
    transform={prepareFiles<MaintenanceProvider>(["logo"])}
    redirect="list"
  >
    <MaintenanceProviderEditionForm />
  </Create>
);
