import { getErrorMessage } from "@elax/utils";
import { Grid, Typography } from "@mui/material";
import {
  BooleanInput,
  Button,
  TextInput,
  useNotify,
  useRecordContext,
} from "react-admin";
import { downloadFile, getBlob } from "../../../providers/data";
import type { Project } from "../type";

export const DeploymentLetterForm = () => {
  const notify = useNotify();
  const project = useRecordContext<Project>();

  const generateLetterSample = async () => {
    try {
      const blob = await getBlob(
        `/projects/${project?.id}/deployment-letter-sample`,
        "application/pdf",
      );
      downloadFile(blob, `Exemple courrier - ${project?.name}.pdf`);
    } catch (error) {
      notify(getErrorMessage(error), {
        type: "error",
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Automatic deployment letter
          </Typography>
        </Grid>

        <Grid item xs={6} textAlign="right">
          <Button
            onClick={generateLetterSample}
            label="Generate letter sample"
            variant="contained"
            sx={{ marginX: 4 }}
          />
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            source="isDeploymentLetterEnabled"
            label="Enable automatic sending of deployment letters"
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            validate={validateRichText}
            source="deploymentLetter.firstPageText"
            label="First page text"
            defaultValue={
              "Dans le cadre de son plan de sobriété énergétique, votre bailleur __{{LANDLORD}}__ a décidé d’équiper ses ballons d’eau chaude d’un thermostat connecté Elax Energie.\n\nCe thermostat améliorera votre système d'eau chaude sanitaire tout en réduisant la consommation électrique de votre chauffe-eau de 25% en moyenne, __soit une économie moyenne de 95€ par an__."
            }
            multiline
            fullWidth
            helperText="Ajouter {{LANDLORD}} pour afficher le nom du bailleur. Ajouter __ de part et d'autre d'un groupe de mots pour les afficher en gras."
          />
        </Grid>

        <Grid item xs={12}>
          <TextInput
            validate={validateRichText}
            source="deploymentLetter.secondPageText"
            label="Second page text"
            defaultValue=""
            multiline
            fullWidth
            helperText="Ajouter {{LANDLORD}} pour afficher le nom du bailleur. Ajouter __ de part et d'autre d'un groupe de mots pour les afficher en gras."
          />
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            source="deploymentLetter.isMaintenanceProviderLogoEnabled"
            label="Display maintenance provider logo"
          />
        </Grid>

        <Grid item xs={12}>
          <BooleanInput
            source="deploymentLetter.isQRCodeEnabled"
            label="Display QR Code"
          />
        </Grid>
      </Grid>
    </>
  );
};

const deprecatedBoldSymbolsRegex = /\*\*[^*]+\*\*/;

const validateRichText = (text: string) => {
  if (deprecatedBoldSymbolsRegex.test(text))
    return '"**" ne sont plus supportés pour faire apparaitre des mots en gras: utilisez plutôt "__".';
};
