import type { DeviceStatus, DeviceTestGroup } from "@elax/business-types";

export { DeviceStatus } from "@elax/business-types";

export interface Device {
  id: string;
  IMEI: number;
  name: string;
  bypassedRemoteSwitch: boolean;
  VersionHardware?: string;
  majorHWVersion: 1 | 2 | 3;
  Telemetry: number;
  heater?: string;
  custom_pilot: boolean;
  HoraireHCHP: any[];
  contractType: ContractType;
  status: DeviceStatus;
  firmwareVersion: string | undefined;
  targetFirmwareVersion: string | undefined;
  isCaServerUpToDate?: boolean | null;
  disabledDemandResponse?: boolean;
  targetCertificate: {
    crc: number;
    generationDate: Date;
    expirationDate: Date;
  };
  currentCertificate: {
    crc: number;
    expirationDate: Date;
  };
  residentAppUrl: string;
  isObserving: boolean;
  lastFirmwareUpdateRequest?: string;
  testGroup?: DeviceTestGroup;
}

export enum ContractType {
  BASE = "BASE",
  HP_HC = "HP/HC",
  UNKNOWN = "UNKNOWN",
}

export enum SeriesName {
  T1 = "T1°",
  T2 = "T2°",
  T1_AVG = "T1°(av)",
  T2_AVG = "T2°(av)",
  T_DIFF = "ΔT°(av)",
  SCHEDULE = "Config Target",
  SCHEDULE_2 = "Config Target (relay 2)",
  CONFIG_ACK = "Config Received",
  CONFIG_ACK_2 = "Config Received (relay 2)",
  CONFIG = "Config Sent",
  CONFIG_2 = "Config Sent (relay 2)",
  ENERGY_CUMULATIVE = "Energy",
  ENERGY = "Energy (columns)",
  ON_TIMER = "On Timer",
  HISTORY = "History",
}

export interface DailyStats {
  day: string;
  energy: number;
  energy_raw: number;
  data_coverage: number;
  inconsistent_data_coverage: number;
}

export interface DailySavingsStats {
  day: string;
}

export interface DevicesFilter {
  id?: string | string[];
  projectId?: string[];
  organizationId?: string[];
  residenceId?: string;
  group?: string;
}
