import { TopToolbar } from "react-admin";
import { SelectColumnsButton } from "../../../layout/SelectColumns";
import { ActionsMenu } from "../../../layout/actions-menu";
import { endUserColumns } from "../List";
import { ImportEndUserConsents } from "./ImportConsents";

export const EndUserActions = () => (
  <TopToolbar>
    <SelectColumnsButton
      preference="endUsers.list.columns"
      columns={endUserColumns}
    />
    <ActionsMenu>
      <ImportEndUserConsents />
    </ActionsMenu>
  </TopToolbar>
);
