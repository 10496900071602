import { getErrorMessage } from "@elax/utils";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { useState } from "react";
import {
  Datagrid,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  TextField,
  useNotify,
  useRecordContext,
} from "react-admin";
import {
  ActionDialog,
  ActionDialogButton,
} from "../../../../layout/actions-menu";
import { axiosClient } from "../../../../providers/data";
import { communicationFields } from "../../../communications/fields";
import { SelectMaintenanceProvider } from "../../../maintenance-provider/fields/SelectMaintenanceProvider";
import type { MaintenanceProvider } from "../../../maintenance-provider/type";
import type { Housing } from "../../types";

export const SendInfoToMaintenanceProviderDialog = () => {
  const record = useRecordContext<Housing>();
  const notify = useNotify();
  const [maintenanceProvider, setMaintenanceProvider] =
    useState<MaintenanceProvider | null>(null);

  const sendEmail = () =>
    axiosClient
      .post(`/housings/${record?.id}/send-info-to-maintenance-provider`, {
        maintenanceProviderId: maintenanceProvider?.id,
      })
      .then(() => {
        notify(
          "Done! Please check #communication-bailleurs for confirmation or error",
          { type: "success" },
        );
      })
      .catch((error: unknown) => {
        notify(`Error: ${getErrorMessage(error)}`, { type: "error" });
      });

  if (!record) {
    return null;
  }

  return (
    <ActionDialog>
      <DialogTitle>
        Send details of housing and device to a maintenance provider
      </DialogTitle>

      <DialogContent>
        <Divider sx={{ mb: 2 }} />
        <strong>Already sent emails:</strong>
        <ReferenceManyField
          reference="communications"
          target="context.housingId"
          pagination={<Pagination />}
          filter={{
            "context.maintenanceProviderId": { $exists: true },
          }}
        >
          <Datagrid bulkActionButtons={false}>
            {communicationFields.date}
            {communicationFields.status}
            {communicationFields.maintenanceProvider}
          </Datagrid>
        </ReferenceManyField>
        <Divider sx={{ mb: 2 }} />
        <Box mb={2}>
          <strong>Device concerned:</strong>{" "}
          <ReferenceField reference="devices" source="deviceId" link="show">
            <TextField source="name" />
          </ReferenceField>
        </Box>
        <Box mb={2}>
          <SelectMaintenanceProvider onChange={setMaintenanceProvider} />
        </Box>
        {maintenanceProvider && (
          <Box mb={2}>
            <strong>Recipients:</strong>
            <br />
            {maintenanceProvider.notificationEmails?.maintenance?.join(", ")}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <ActionDialogButton>Cancel</ActionDialogButton>
        <ActionDialogButton
          variant="contained"
          onClick={async (_, onClose) => {
            await sendEmail();
            onClose();
          }}
          disabled={!maintenanceProvider}
        >
          Send email
        </ActionDialogButton>
      </DialogActions>
    </ActionDialog>
  );
};
