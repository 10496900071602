import { IncidentStatus } from "@elax/business-types";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { Alert, Box, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  RecordContext,
  ReferenceManyField,
  WithListContext,
} from "react-admin";
import { DialogActionIconButton } from "../../../../../layout/actions-menu/ActionDialog";
import { ActionMenuStateProvider } from "../../../../../layout/actions-menu/context";
import { useActivityLogs } from "../../../../activity-logs/hooks/useActivityLogs";
import { EditIncidentDialog } from "../../../../incidents/edit/EditIncident";
import { IncidentHistory } from "../../../../incidents/fields/Events";
import { HasIncidentCommunicationSent } from "../../../../incidents/fields/HasIncidentCommunicationSent";
import { IncidentStatusField } from "../../../../incidents/fields/IncidentStatusField";
import { incidentTypeSettings } from "../../../../incidents/fields/IncidentTypeField";
import { ResolveIncidentActionsMenu } from "../../../../incidents/fields/ResolveIncidentActionsMenu";
import { incidentActorLabels } from "../../../../incidents/types";
import type { Incident } from "../../../../incidents/types";
import { UserField } from "../../../../users/fields/UserField";
import InfoList from "../InfoList";

const isIncidentUnresolved = (incident: Incident) =>
  incident.status === IncidentStatus.OPENED;
const isIncidentResolved = (incident: Incident) =>
  !isIncidentUnresolved(incident);

export default function IncidentsInfoList() {
  return (
    <ReferenceManyField
      reference="incidents"
      target="deviceId"
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={1000}
    >
      <WithListContext<Incident>
        render={({ data }) => {
          if (!data || data.length === 0) {
            return null;
          }
          const resolvedIncidents = data.filter((incident) =>
            isIncidentResolved(incident),
          );
          return (
            <InfoList label="Incidents">
              {data.filter(isIncidentUnresolved).map((incident) => (
                <IncidentItem incident={incident} key={incident.id} />
              ))}

              <InfoList.Collapsible>
                {resolvedIncidents.map((incident) => (
                  <IncidentItem incident={incident} key={incident.id} />
                ))}
              </InfoList.Collapsible>
            </InfoList>
          );
        }}
      />
    </ReferenceManyField>
  );
}

const IncidentItem = ({ incident }: { incident: Incident }) => {
  const lastEvent = incident.events.at(-1);

  return (
    <RecordContext.Provider value={incident}>
      <Stack
        direction="column"
        gap={0.5}
        alignItems="center"
        marginTop={1}
        marginBottom={1}
        p={1}
        sx={{
          color: incidentTypeSettings[incident.type]?.color["400"],
          border: `solid 1px ${
            incidentTypeSettings[incident.type]?.color["400"] ?? "black"
          }`,
          borderRadius: 1,
        }}
      >
        <Stack direction="row" gap={0.5} alignItems="center">
          <Box flexGrow={1} display="flex" flexDirection="column" gap={0.5}>
            <Typography
              variant="body2"
              fontWeight="bold"
              alignItems="center"
              display="flex"
              gap={0.5}
            >
              <IncidentStatusField />
              {incidentTypeSettings[incident.type]?.label ?? incident.type}

              <HasIncidentCommunicationSent />
            </Typography>
            <Stack direction="row" gap={0.5}>
              <AddCircleOutlineIcon fontSize="small" />
              <Stack direction="column">
                <Typography variant="body2">
                  {dayjs(incident.createdAt).format("DD/MM/YY HH:mm")}
                </Typography>
                <Typography variant="body2">
                  ({dayjs(incident.createdAt).fromNow()})
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row" gap={0.5}>
              <AssignmentIndIcon fontSize="small" />
              <Stack direction="column">
                <Typography variant="body2">
                  {incident.assignedTo &&
                    incidentActorLabels[incident.assignedTo].label}
                </Typography>
                <Typography variant="body2">
                  {lastEvent?.action || lastEvent?.comment}
                </Typography>
              </Stack>
            </Stack>
            {lastEvent?.status === IncidentStatus.CLOSED && (
              <Stack direction="row" gap={0.5}>
                <TaskAltIcon fontSize="small" />
                <Typography variant="body2">
                  {`${dayjs(lastEvent.datetime).format(
                    "DD/MM/YY HH:mm",
                  )} (in ${dayjs
                    .duration(
                      dayjs(lastEvent.datetime).diff(incident.createdAt),
                    )
                    .humanize()})`}
                  <UserField
                    userId={lastEvent.userId}
                    emptyText="AUTO"
                    variant="body2"
                  />
                </Typography>
              </Stack>
            )}
          </Box>
          <Stack direction="column" gap={0.5}>
            <ActionMenuStateProvider>
              <DialogActionIconButton
                dialog={<EditIncidentDialog />}
                sx={{ padding: 0, height: 34 }}
              >
                <EditIcon sx={{ marginLeft: 0.5, fontSize: 16 }} />
              </DialogActionIconButton>
            </ActionMenuStateProvider>
            <IncidentHistory size="small" />
            {isIncidentUnresolved(incident) && <ResolveIncidentActionsMenu />}
          </Stack>
        </Stack>
        <IncidentCreatedByAlert />
      </Stack>
    </RecordContext.Provider>
  );
};

function IncidentCreatedByAlert() {
  const { data } = useActivityLogs();
  const createdBy = data?.find((log) => log.action === "create");

  if (!createdBy) {
    return null;
  }

  return (
    <Alert severity="warning">
      <Typography variant="body2" fontWeight="bold">
        Created manually by
      </Typography>
      <UserField userId={createdBy.userId} variant="body2" fontWeight="bold" />
    </Alert>
  );
}
