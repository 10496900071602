import { NumberField } from "ra-ui-materialui";
import type { NumberFieldProps } from "ra-ui-materialui";
import { useRecordContext } from "react-admin";

interface ColoredNumberFieldProps extends NumberFieldProps {
  getColor?: (value: number) => string;
}

/**
 * @example
 * <ColoredNumberField source="score" getColor={(value) => value < 0 ? "red" : "green" } />
 */
export const ColoredNumberField = (props: ColoredNumberFieldProps) => {
  const record = useRecordContext(props);
  if (!record || !props.source) {
    return null;
  }

  const color = props.getColor?.(record[props.source]);
  return <NumberField {...props} sx={{ color }} />;
};
