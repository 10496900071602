import { DeviceTestGroup } from "@elax/business-types";
import { useRecordContext } from "react-admin";
import { EnumField } from "../../../fields/EnumField";
import type { Device } from "../types";

export const DeviceTestGroupField = ({ label }: { label?: string }) => {
  const record = useRecordContext<Device>();
  return record?.testGroup ? (
    <EnumField
      label={label || "Test group"}
      source="testGroup"
      colors={{
        [DeviceTestGroup.DEFAULT]: "#9c27b0",
        [DeviceTestGroup.AUTO_REBOOT]: "#9c27b0",
      }}
    />
  ) : null;
};
