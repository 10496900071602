import { get } from "lodash";
import { useRecordContext } from "react-admin";

export const SatisfiedField = ({
  source,
}: {
  source: string;
  label?: string;
}) => {
  const record = useRecordContext();

  const value = get(record, source);

  return (
    <div
      style={{
        backgroundColor: (() => {
          if (value === true) return "#4caf508c";
          if (value === false) return "#f44336a1";
          return "#dadada";
        })(),
        borderRadius: 5,
        padding: "2px 5px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === true ? "Yes" : value === false ? "No" : "-"}
    </div>
  );
};
