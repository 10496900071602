import { Chip } from "@mui/material";
import { useListContext, useResourceContext } from "react-admin";

interface IdsFilterProps {
  source: string;
  label?: string;
}

export const IdsFilter = ({ source }: IdsFilterProps) => {
  const { filterValues } = useListContext();
  const resourceName = useResourceContext();

  const value = filterValues[source];

  const count = typeof value === "string" ? 1 : value?.length ?? 0;

  return <Chip label={`${count} ${resourceName}`} />;
};
