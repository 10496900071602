export interface ContactDetailsBase {
  createdAt: Date;
  source: string;
  validationHistory: {
    source?: string;
    status: string;
    date: Date;
  }[];
  status: "validated" | "risky" | "invalid" | "deleted" | "unknown";
  score?: number;
}

export interface EmailContactDetails extends ContactDetailsBase {
  emailAddress: string;
}

export interface PhoneNumberContactDetails extends ContactDetailsBase {
  number: string;
}

interface Contacts<T> {
  validated: string[];
  all: T[];
}

interface EndUserContacts {
  emails: Contacts<EmailContactDetails>;
  phoneNumbers: Contacts<PhoneNumberContactDetails>;
}

interface EndUserHousingHistory {
  housingId: string;
  date: Date;
  type: "ADDED" | "REMOVED";
}

export interface EndUser {
  id: string;
  title1?: string;
  firstName1?: string;
  lastName1?: string;
  title2?: string;
  firstName2?: string;
  lastName2?: string;
  nps?: number;
  contacts: EndUserContacts;
  consents: Consents;
  housingHistory: EndUserHousingHistory[];
  notes?: string;
}

export enum ConsentType {
  DEMAND_RESPONSE = "DEMAND_RESPONSE",
  TERMS_OF_SERVICES = "TERMS_OF_SERVICES",
  ELECTRICITY_DATA_COLLECTION = "ELECTRICITY_DATA_COLLECTION",
  DEVICE_DATA_COLLECTION = "DEVICE_DATA_COLLECTION",
}

export interface Consent {
  consent: boolean | null;
  date: Date;
  proofUrl?: string;
}

export type Consents = Partial<Record<ConsentType, Consent>>;

export const consentTypeLabel: Record<ConsentType, string> = {
  [ConsentType.TERMS_OF_SERVICES]: "Terms of Service",
  [ConsentType.DEVICE_DATA_COLLECTION]: "Device Data Collection",
  [ConsentType.DEMAND_RESPONSE]: "Flex",
  [ConsentType.ELECTRICITY_DATA_COLLECTION]: "Electricity Data Collection",
};

export interface EndUserZendesk {
  id: string;
  zendeskUrl: string;
}

export interface EndUserZendeskTickets {
  id: string;
  zendeskTickets: ZendeskTicket;
}

export interface ZendeskTicket {
  url: string;
  createdAt: Date;
  status: string;
  description: string;
  askingType: string;
  problemCause: string;
}

export enum ZendeskTicketStatus {
  NEW = "new",
  OPEN = "open",
  PENDING = "pending",
  HOLD = "hold",
  SOLVED = "solved",
  CLOSED = "closed",
}

export const zendeskTicketStatusColors: Record<ZendeskTicketStatus, string> = {
  [ZendeskTicketStatus.NEW]: "#FFB057",
  [ZendeskTicketStatus.OPEN]: "#E34E34",
  [ZendeskTicketStatus.PENDING]: "#3091EC",
  [ZendeskTicketStatus.HOLD]: "#2F3941",
  [ZendeskTicketStatus.SOLVED]: "#87929D",
  [ZendeskTicketStatus.CLOSED]: "#87929D",
};

export enum CommunicationType {
  EMAIL_SENT = "EMAIL_SENT",
  ADDED_TO_LIST = "ADDED_TO_LIST",
}
