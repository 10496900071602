import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import type { ReactNode } from "react";
import { useStore } from "react-admin";

export const DatetimeFilter = () => {
  const [showDate, setShowDate] = useDatetimePreference();

  return (
    <MenuItem
      selected={showDate}
      onClick={() => {
        setShowDate((v) => !v);
      }}
    >
      Day
    </MenuItem>
  );
};

export function Datetime({ frame }: { frame: { ts: string } }): ReactNode {
  const [showDate] = useDatetimePreference();
  return (
    <>{dayjs(frame.ts).format(showDate ? "DD/MM/YYYY HH:mm:ss" : "HH:mm:ss")}</>
  );
}

const useDatetimePreference = () => useStore("frames.showDate", true);
