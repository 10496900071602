import { Autocomplete, TextField } from "@mui/material";
import { keyBy } from "lodash";
import { useGetList } from "react-admin";
import type { MaintenanceProvider } from "../type";

interface SelectMaintenanceProviderProps {
  filter?: Record<string, any>;
  onChange: (maintenanceProvider: MaintenanceProvider | null) => void;
}

export const SelectMaintenanceProvider = ({
  filter,
  onChange,
}: SelectMaintenanceProviderProps) => {
  const { data } = useGetList<MaintenanceProvider>("maintenance-providers", {
    filter,
    pagination: { perPage: 100, page: 1 },
  });

  const maintenanceProviderById = keyBy(data, "id");
  const maintenanceProviderIds = Object.keys(maintenanceProviderById);

  return (
    <Autocomplete
      size="medium"
      sx={{ minWidth: 200 }}
      options={maintenanceProviderIds}
      getOptionLabel={(id) => maintenanceProviderById[id].name}
      renderInput={(params) => (
        <TextField {...params} label="Maintenance provider" />
      )}
      loading={!data}
      onChange={(_, id) => onChange(id ? maintenanceProviderById[id] : null)}
    />
  );
};
