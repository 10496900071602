import { green, grey, orange, purple, red } from "@mui/material/colors";
import { EnumField } from "../../../fields/EnumField";
import type { ContactDetailsBase } from "../type";

export const ContactStatusField = ({
  label = "Status",
}: {
  label?: string;
}) => {
  return (
    <EnumField
      source="status"
      label={label}
      colors={
        {
          validated: green[800],
          deleted: red[800],
          invalid: purple[800],
          risky: orange[500],
          unknown: grey[500],
        } satisfies Record<ContactDetailsBase["status"], string>
      }
    />
  );
};
