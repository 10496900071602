import HelpIcon from "@mui/icons-material/HelpOutline";
import { Chip, Tooltip } from "@mui/material";
import { incidentTag } from "../types";

interface IncidentTagProps {
  value: string;
}

export const IncidentTag = ({ value }: IncidentTagProps) => {
  const tag = incidentTag[value];

  if (!tag) {
    return null;
  }

  return (
    <Chip
      key={value}
      label={tag.label}
      sx={{
        backgroundColor: tag.color["100"],
        color: tag.color["800"],
        borderRadius: 1,
        m: 0.5,
        height: 22,
        "& .MuiChip-deleteIcon": {
          color: tag.color["800"],
          fontSize: 16,
        },
      }}
      onDelete={() => {}}
      deleteIcon={
        <Tooltip title={tag.description} followCursor>
          <HelpIcon />
        </Tooltip>
      }
    />
  );
};
