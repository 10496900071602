import { mapValues } from "lodash";
import { EnumField } from "../../../fields/EnumField";
import { communicationStatusConfigs } from "../type";

interface CommunicationStatusFieldProps {
  label?: string;
  source?: string;
}

export function CommunicationStatusField({
  label,
  source,
}: CommunicationStatusFieldProps) {
  return (
    <EnumField
      label={label || "Status"}
      source={source ?? "status"}
      colors={mapValues(communicationStatusConfigs, (v) => v.color["400"])}
      labels={mapValues(communicationStatusConfigs, (v) => v.label)}
    />
  );
}
