import EDRIcon from "@mui/icons-material/Diversity2";
import { EDRCreate } from "./edit/Create";
import { EDRList } from "./list";
import { EDRShow } from "./show";

export const edrResource = {
  icon: EDRIcon,
  edit: undefined,
  list: EDRList,
  show: EDRShow,
  create: EDRCreate,
  options: { label: "EDR (R2 POC)" },
};

export { EDRIcon };
