import ElectricMeter from "@mui/icons-material/ElectricMeter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { flatMap, pick } from "lodash";
import {
  TextField,
  TextInput,
  useGetList,
  useRecordContext,
} from "react-admin";
import type { EditFormWithDialogProps } from "../../../display/EditFormDialog";
import { EditFormDialog } from "../../../display/EditFormDialog";
import { FieldItem } from "../../../display/FieldItem";
import { getDocumentURL } from "../../documents/documentsHelpers";
import type { Housing } from "../types";

const useHousingPDLPictures = () => {
  const record = useRecordContext();
  const { data: interventions } = useGetList(
    "interventions",
    { filter: { housingId: record?.id } },
    { enabled: !!record },
  );
  const { data: docs } = useGetList(
    "documents",
    {
      filter: {
        id: flatMap(interventions, (i) => i.documentIds),
        context: ["PDL or PRM photo", "enduser_PRM"],
      },
    },
    { enabled: !!interventions },
  );

  return docs;
};

export const PDL = () => {
  return (
    <>
      <FieldItem
        label="PDL"
        icon={<ElectricMeter />}
        showHistory={true}
        editDialog={<EditPDLDialog />}
      >
        <TextField source="pdl" />
      </FieldItem>
    </>
  );
};

export function EditPDLDialog({ dialogProps }: EditFormWithDialogProps) {
  const docs = useHousingPDLPictures();

  return (
    <EditFormDialog<Housing, Pick<Housing, "pdl">>
      dialogProps={dialogProps}
      getMutationAxiosConfig={(record) => ({
        method: "PUT",
        url: `/housings/${record?.id}/pdl`,
      })}
      getFormRecordFromRecord={(record) => pick(record, ["pdl"])}
    >
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextInput source="pdl" fullWidth />
        </Grid>
        <Grid item sm={12}>
          {docs?.map((doc) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">
                  Photo du compteur le{" "}
                  {doc?.createdAt && dayjs(doc.createdAt).format("YYYY-MM-DD")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <img
                  alt={doc?.context}
                  src={getDocumentURL("interventions", doc)}
                  width={400}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </EditFormDialog>
  );
}
