import type { Color } from "@mui/material";
import { amber, grey, orange, purple, red } from "@mui/material/colors";

export enum InterventionStatus {
  TO_BE_PLANNED = "TO_BE_PLANNED",
  PLANNED = "PLANNED",
  NOT_BOOKED = "NOT_BOOKED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export const interventionClosedStatuses = [
  InterventionStatus.SUCCESS,
  InterventionStatus.FAILED,
  InterventionStatus.NOT_BOOKED,
];

export enum InterventionVisitStatus {
  NOTHING_TO_REPORT = "NOTHING_TO_REPORT",
  ABSENT_END_USER = "ABSENT_END_USER",
  INSTALLATION_NOT_POSSIBLE = "INSTALLATION_NOT_POSSIBLE",
  INSTALLATION_CURRENTLY_NOT_POSSIBLE = "INSTALLATION_CURRENTLY_NOT_POSSIBLE",
  NOT_VISITED = "NOT_VISITED",
  RGPD_REFUSAL = "RGPD_REFUSAL",
  PRODUCT_REFUSAL = "PRODUCT_REFUSAL",
  UNKNOWN_REFUSAL = "UNKNOWN_REFUSAL",
}

export enum InterventionPriority {
  P1 = 1,
  P2 = 2,
  P3 = 3,
  P4 = 4,
}

export enum InterventionType {
  INSTALLATION = "INSTALLATION",
  REPLACEMENT = "REPLACEMENT",
  REMOVAL = "REMOVAL",
  PROBE_REPLACEMENT = "PROBE_REPLACEMENT",
  DEPRECATED_THERMOSTAT = "THERMOSTAT",
  OTHER = "OTHER",
}

export enum InterventionAccounting {
  INSTALLATION = "INSTALLATION",
  MAINTENANCE = "MAINTENANCE",
  REINTERVENTION_MAINTENANCE = "REINTERVENTION_MAINTENANCE",
  REINTERVENTION_INSTALLATION = "REINTERVENTION_INSTALLATION",
}

export enum InterventionSourcingStatus {
  APPOINTMENT_TAKEN = "Rendez-vous pris",
  INCORRECT_CONTACT = "Coordonnées erronées",
  MISSING_CONTACT = "Coordonnées manquantes",
  NO_ANSWER = "Pas de réponse",
  OVER_QUOTA = "Quota atteint",
  HAS_MOVED = "A déménagé",
  AUTONOMOUS_INSTALLATION = "Installation autonome",
  NOTHING_TO_REPORT = "RAS",
  NO_AVAILABILITY = "Pas de disponibilité",
  SUBSCRIPTION_REFUSAL = "Refus abonnement",
  PRODUCT_REFUSAL = "Refus produit",
  UNKNOWN_REFUSAL = "Refus pour raison inconnue",
  HEATER_CHANGE = "Ballon en cours de changement",
  HEATER_NOT_COMPATIBLE = "Installation incompatible",
  TECHNICAL_PROBLEM = "Problème technique",
}

const possibleSourcingStatusDuringSourcing = [
  InterventionSourcingStatus.NOTHING_TO_REPORT,
  InterventionSourcingStatus.NO_ANSWER,
  InterventionSourcingStatus.MISSING_CONTACT,
  InterventionSourcingStatus.INCORRECT_CONTACT,
  InterventionSourcingStatus.NO_AVAILABILITY,
  InterventionSourcingStatus.HAS_MOVED,
  InterventionSourcingStatus.SUBSCRIPTION_REFUSAL,
  InterventionSourcingStatus.PRODUCT_REFUSAL,
  InterventionSourcingStatus.UNKNOWN_REFUSAL,
  InterventionSourcingStatus.HEATER_CHANGE,
  InterventionSourcingStatus.HEATER_NOT_COMPATIBLE,
  InterventionSourcingStatus.TECHNICAL_PROBLEM,
  InterventionSourcingStatus.OVER_QUOTA,
];

export const interventionPossibleSourcingStatusByStatus: Record<
  InterventionStatus,
  InterventionSourcingStatus[]
> = {
  [InterventionStatus.NOT_BOOKED]: possibleSourcingStatusDuringSourcing,
  [InterventionStatus.TO_BE_PLANNED]: possibleSourcingStatusDuringSourcing,
  [InterventionStatus.PLANNED]: [
    InterventionSourcingStatus.APPOINTMENT_TAKEN,
    InterventionSourcingStatus.AUTONOMOUS_INSTALLATION,
  ],
  [InterventionStatus.SUCCESS]: [
    ...possibleSourcingStatusDuringSourcing,
    InterventionSourcingStatus.APPOINTMENT_TAKEN,
    InterventionSourcingStatus.AUTONOMOUS_INSTALLATION,
  ],
  [InterventionStatus.FAILED]: [
    ...possibleSourcingStatusDuringSourcing,
    InterventionSourcingStatus.APPOINTMENT_TAKEN,
    InterventionSourcingStatus.AUTONOMOUS_INSTALLATION,
  ],
};

export enum InterventionRemovalResponsible {
  ELAX = "ELAX",
  LANDLORD = "LANDLORD",
  OTHER = "OTHER",
}

export enum InterventionDeviceLocationAfterRemoval {
  STILL_IN_HOUSING = "STILL_IN_HOUSING",
  NOT_IN_HOUSING = "NOT_IN_HOUSING",
  UNKNOWN = "UNKNOWN",
}

export const interventionAccountingLabels: Record<
  InterventionAccounting,
  string
> = {
  [InterventionAccounting.INSTALLATION]: "Installation",
  [InterventionAccounting.MAINTENANCE]: "SAV",
  [InterventionAccounting.REINTERVENTION_MAINTENANCE]:
    "Reinter - Echec installation",
  [InterventionAccounting.REINTERVENTION_INSTALLATION]: "Reinter - Echec SAV",
};

export const interventionTypeColors: Record<InterventionType, string> = {
  [InterventionType.INSTALLATION]: "#80b1d3",
  [InterventionType.REPLACEMENT]: "#bc80bd",
  [InterventionType.OTHER]: "#d9d9d9",
  [InterventionType.PROBE_REPLACEMENT]: "#fb8072",
  [InterventionType.DEPRECATED_THERMOSTAT]: "#ff9800",
  [InterventionType.REMOVAL]: "#3f51b5",
};

export enum InterventionSourcingType {
  AUTONOMOUS = "AUTONOMOUS",
  DOOR_TO_DOOR = "DOOR_TO_DOOR",
  PASSIVE_CALL = "PASSIVE_CALL",
  ACTIVE_CALL = "ACTIVE_CALL",
  EMAIL = "EMAIL",
  BUILDING_SIGN = "BUILDING_SIGN",
  LETTER = "LETTER",
  SMS = "SMS",
  UNKNOWN = "UNKNOWN",
}

export interface Intervention {
  id: string;
  housingId: string;
  deviceId?: string;
  replacedDeviceId?: string;
  datetime?: Date;
  type: InterventionType;
  data?: any;
  status?: InterventionStatus;
  sourcingType: InterventionSourcingType;
  sourcingStatus: InterventionSourcingStatus;
  visitStatus: InterventionVisitStatus;
  removalResponsible?: InterventionRemovalResponsible;
  deviceLocationAfterRemoval?: InterventionDeviceLocationAfterRemoval;
  comment?: string;
  technician?: string;
  externalId?: string;
}

export const interventionStatusColors: Record<InterventionStatus, string> = {
  [InterventionStatus.TO_BE_PLANNED]: orange["500"],
  [InterventionStatus.SUCCESS]: "#4caf50",
  [InterventionStatus.PLANNED]: "#03a9f4",
  [InterventionStatus.FAILED]: "#f44336",
  [InterventionStatus.NOT_BOOKED]: "#9e9e9e",
};

export const interventionStatusLabels: Record<InterventionStatus, string> = {
  [InterventionStatus.SUCCESS]: "succès",
  [InterventionStatus.TO_BE_PLANNED]: "À planifier",
  [InterventionStatus.PLANNED]: "planifié",
  [InterventionStatus.FAILED]: "échec",
  [InterventionStatus.NOT_BOOKED]: "pas de RDV",
};

export const interventionsVisitStatusLabels: Record<
  InterventionVisitStatus,
  string
> = {
  [InterventionVisitStatus.NOTHING_TO_REPORT]: "RAS",
  [InterventionVisitStatus.ABSENT_END_USER]: "Locataire absent",
  [InterventionVisitStatus.INSTALLATION_NOT_POSSIBLE]:
    "Installation impossible",
  [InterventionVisitStatus.INSTALLATION_CURRENTLY_NOT_POSSIBLE]:
    "Installation pour le moment impossible",
  [InterventionVisitStatus.NOT_VISITED]: "Technicien absent",
  [InterventionVisitStatus.RGPD_REFUSAL]: "Refus RGPD",
  [InterventionVisitStatus.PRODUCT_REFUSAL]: "Refus produit",
  [InterventionVisitStatus.UNKNOWN_REFUSAL]: "Refus pour raison inconnue",
};

export const interventionsVisitStatusColors: Record<
  InterventionVisitStatus,
  string
> = {
  [InterventionVisitStatus.NOTHING_TO_REPORT]: "#b2bec3",
  [InterventionVisitStatus.ABSENT_END_USER]: "#ff7675",
  [InterventionVisitStatus.INSTALLATION_NOT_POSSIBLE]: "#d63031",
  [InterventionVisitStatus.INSTALLATION_CURRENTLY_NOT_POSSIBLE]: "#ff7675",
  [InterventionVisitStatus.NOT_VISITED]: "#ff7675",
  [InterventionVisitStatus.RGPD_REFUSAL]: "#d63031",
  [InterventionVisitStatus.PRODUCT_REFUSAL]: "#d63031",
  [InterventionVisitStatus.UNKNOWN_REFUSAL]: "#d63031",
};

export const interventionPriorityLabels: Record<InterventionPriority, string> =
  {
    [InterventionPriority.P1]: "P1 - intervention à J+1",
    [InterventionPriority.P2]: "P2 - intervention à J+3",
    [InterventionPriority.P3]: "P3 - intervention à J+10",
    [InterventionPriority.P4]: "P4 - intervention à PDT Déploiement",
  };

export const interventionPriorityColors: Record<InterventionPriority, Color> = {
  [InterventionPriority.P1]: red,
  [InterventionPriority.P2]: orange,
  [InterventionPriority.P3]: purple,
  [InterventionPriority.P4]: grey,
};

export const interventionSourcingTypeColors: Record<
  InterventionSourcingType,
  string
> = {
  [InterventionSourcingType.ACTIVE_CALL]: "#3f51b5",
  [InterventionSourcingType.AUTONOMOUS]: "#ffc107",
  [InterventionSourcingType.DOOR_TO_DOOR]: "#8bc34a",
  [InterventionSourcingType.PASSIVE_CALL]: "#009688",
  [InterventionSourcingType.EMAIL]: amber["400"],
  [InterventionSourcingType.BUILDING_SIGN]: amber["600"],
  [InterventionSourcingType.LETTER]: amber["800"],
  [InterventionSourcingType.SMS]: amber["200"],
  [InterventionSourcingType.UNKNOWN]: "#607d8b",
};

export const interventionSourcingTypeLabels: Record<
  InterventionSourcingType,
  string
> = {
  [InterventionSourcingType.ACTIVE_CALL]: "Appel sortant",
  [InterventionSourcingType.AUTONOMOUS]: "Autonome",
  [InterventionSourcingType.DOOR_TO_DOOR]: "Porte à porte",
  [InterventionSourcingType.PASSIVE_CALL]: "Appel entrant",
  [InterventionSourcingType.EMAIL]: "Email",
  [InterventionSourcingType.BUILDING_SIGN]: "Affiche",
  [InterventionSourcingType.LETTER]: "Courrier",
  [InterventionSourcingType.SMS]: "SMS",
  [InterventionSourcingType.UNKNOWN]: "Inconnu",
};

export const interventionSourcingStatusLabels: Record<
  InterventionSourcingStatus,
  string
> = {
  [InterventionSourcingStatus.APPOINTMENT_TAKEN]: "Rendez-vous pris",
  [InterventionSourcingStatus.AUTONOMOUS_INSTALLATION]: "Installation autonome",
  [InterventionSourcingStatus.HAS_MOVED]: "A déménagé",
  [InterventionSourcingStatus.INCORRECT_CONTACT]: "Coordonnées erronées",
  [InterventionSourcingStatus.MISSING_CONTACT]: "Coordonnées manquantes",
  [InterventionSourcingStatus.NO_ANSWER]: "Pas de réponse",
  [InterventionSourcingStatus.OVER_QUOTA]: "Quota atteint",
  [InterventionSourcingStatus.NOTHING_TO_REPORT]: "Non contacté",
  [InterventionSourcingStatus.NO_AVAILABILITY]: "Pas de disponibilité",
  [InterventionSourcingStatus.SUBSCRIPTION_REFUSAL]: "Refus abonnement",
  [InterventionSourcingStatus.PRODUCT_REFUSAL]: "Refus produit",
  [InterventionSourcingStatus.UNKNOWN_REFUSAL]: "Refus pour raison inconnue",
  [InterventionSourcingStatus.HEATER_CHANGE]: "Ballon en cours de changement",
  [InterventionSourcingStatus.HEATER_NOT_COMPATIBLE]:
    "Installation incompatible",
  [InterventionSourcingStatus.TECHNICAL_PROBLEM]: "Problème technique",
};

export const interventionSourcingStatusColors: Record<
  InterventionSourcingStatus,
  string
> = {
  [InterventionSourcingStatus.APPOINTMENT_TAKEN]: "#00b894",
  [InterventionSourcingStatus.AUTONOMOUS_INSTALLATION]: "#0984e3",
  [InterventionSourcingStatus.HAS_MOVED]: "#e17055",
  [InterventionSourcingStatus.INCORRECT_CONTACT]: "#ff7675",
  [InterventionSourcingStatus.MISSING_CONTACT]: "#ff7675",
  [InterventionSourcingStatus.NO_ANSWER]: "#0984e3",
  [InterventionSourcingStatus.OVER_QUOTA]: "#e17055",
  [InterventionSourcingStatus.NOTHING_TO_REPORT]: "#b2bec3",
  [InterventionSourcingStatus.NO_AVAILABILITY]: "#e17055",
  [InterventionSourcingStatus.SUBSCRIPTION_REFUSAL]: "#ff7675",
  [InterventionSourcingStatus.PRODUCT_REFUSAL]: "#ff7675",
  [InterventionSourcingStatus.UNKNOWN_REFUSAL]: "#ff7675",
  [InterventionSourcingStatus.HEATER_CHANGE]: "#ff7675",
  [InterventionSourcingStatus.HEATER_NOT_COMPATIBLE]: "#ff7675",
  [InterventionSourcingStatus.TECHNICAL_PROBLEM]: "#ff7675",
};

export const interventionRemovalResponsibleLabels: Record<
  InterventionRemovalResponsible,
  string
> = {
  [InterventionRemovalResponsible.ELAX]: "Taken off for Elax",
  [InterventionRemovalResponsible.LANDLORD]: "Taken off for Landlord",
  [InterventionRemovalResponsible.OTHER]: "Taken off for others",
};

export const interventionDeviceLocationAfterRemovalLabels: Record<
  InterventionDeviceLocationAfterRemoval,
  string
> = {
  [InterventionDeviceLocationAfterRemoval.STILL_IN_HOUSING]: "Still in housing",
  [InterventionDeviceLocationAfterRemoval.NOT_IN_HOUSING]: "Not in housing",
  [InterventionDeviceLocationAfterRemoval.UNKNOWN]: "Unknown",
};
