export enum DemandResponseType {
  UP = "CONSUMPTION_UP",
  DOWN = "CONSUMPTION_DOWN",
}

type DemandResponseEventType =
  | "declared"
  | "valorized"
  | "per_sent"
  | "per_acknowledged"
  | "peb_sent";

export interface DemandResponse {
  id: string;
  pool: string;
  startDate: Date;
  endDate: Date;
  events?: {
    type: DemandResponseEventType;
    date: Date;
  }[];
  cancelledAt?: Date;
  priceAmountInEuros?: number;
  powerDeclaredInMW?: number;
  powerValorizedInMW?: number;
  powerMeasuredInMW?: number;
  powerRealizedInMW?: number;
  avoidedCO2WithAverageMethodInKg?: number;
  avoidedCO2WithMarginalMethodInKg?: number;
  efficiency?: number;
  accuracyDeclared?: number;
  accuracyRealized?: number;
  profitsInEuros?: number;
  type: DemandResponseType;
  startTime?: string;
  endTime?: string;
}
