import { Paper } from "@mui/material";
import { useRef, useState } from "react";
import { useMeasure } from "react-use";
import { useAxiosQuery } from "../../../providers/data-hooks/useAxiosQuery";

interface MetabaseEmbedProps {
  type: "dashboard" | "question";
  metabaseId: number;
  filter?: Record<string, string | number>;
  initialHeight?: number;
  withTitle?: boolean;
}

export const MetabaseEmbed = ({
  type,
  metabaseId,
  filter,
  initialHeight = 1200,
  withTitle = true,
}: MetabaseEmbedProps) => {
  const { data } = useAxiosQuery<string>({
    url: `/metabase/${type}/${metabaseId}`,
    params: { ...filter, withTitle },
  });

  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const [height, setHeight] = useState(initialHeight);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  if (!data) {
    return null;
  }

  return (
    <Paper ref={ref} sx={{ display: "flex", flexGrow: 1 }}>
      <iframe
        src={data}
        ref={iframeRef}
        frameBorder={0}
        width={width}
        height={height}
        scrolling="no"
        onLoad={() => {
          try {
            setHeight(
              iframeRef.current?.contentWindow?.document?.body?.scrollHeight ??
                initialHeight,
            );
          } catch {
            // on cross origin document is not accessible
          }
        }}
      />
    </Paper>
  );
};
