import { Menu, MenuItem, styled } from "@mui/material";
import { useState } from "react";
import { HistoryItemType, historyItemColor } from "../../../history";
import { useHistoryTimeline } from "./history";

export enum Track {
  HISTORY = "History Events",
  NONE = "Previous Navigation",
}

const sampleColors: Record<Track, string> = {
  [Track.HISTORY]: historyItemColor[HistoryItemType.ALERT],
  [Track.NONE]: "black",
};

interface TrackSelectorProps {
  value: Track;
  onChange: (value: Track) => void;
}

export const TrackSelector = ({ value, onChange }: TrackSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Anchor
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ backgroundColor: sampleColors[value] }}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.values(Track).map((track) => (
          <MenuItem
            key={track}
            onClick={() => {
              onChange(track);
              handleClose();
            }}
            selected={track === value}
          >
            {track}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export const useTimelineData = (track: Track) => {
  const events = useHistoryTimeline({ enabled: track === Track.HISTORY });

  if (track === Track.HISTORY) {
    return events ?? {};
  }

  return {};
};

const Anchor = styled("div")`
  width: 18px;
  height: 18px;
  border: solid 1px black;
  margin: 2px;
  border-radius: 3px;
  position: absolute;
  top: 0;
  right: 0;
`;
