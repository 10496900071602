import { EnumField } from "../../../fields/EnumField";
import { DeviceStatus } from "../types";

export const DeviceStatusField = ({ label }: { label?: string }) => (
  <EnumField
    label={label || "Status"}
    source="status"
    colors={{
      [DeviceStatus.OUT_OF_ORDER]: "#f44336",
      [DeviceStatus.PRODUCTION]: "#607d8b",
      [DeviceStatus.STOCK]: "#795548",
      [DeviceStatus.USED]: "#4caf50",
    }}
  />
);
