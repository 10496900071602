import { Autocomplete, TextField } from "@mui/material";
import { uniqBy } from "lodash";
import { useState } from "react";
import { useGetList } from "react-admin";
import type { Device } from "../types";

interface SelectDeviceProps {
  label?: string;
  value: Device | null;
  onChange: (value: Device | null) => void;
  filter?: any;
}

export const SelectDevice = ({
  label,
  value,
  onChange,
  filter,
}: SelectDeviceProps) => {
  const [searchText, setSearchText] = useState("");

  const { data } = useGetList("devices", {
    pagination: { perPage: 30, page: 1 },
    filter: { ...filter, q: searchText },
    meta: { fields: ["id", "name"] },
  });

  return (
    <Autocomplete
      value={value}
      onChange={(_, value) => {
        onChange(value);
      }}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => option?.name ?? ""}
      options={uniqBy([value, ...(data || [])], "id")}
      inputValue={searchText}
      onInputChange={(event, newInputValue) => {
        setSearchText(newInputValue);
      }}
      loading={!data}
      renderInput={(params) => {
        return <TextField {...params} label={label ?? "Device"} />;
      }}
    />
  );
};
