import type { CSSProperties } from "react";
import logo from "./elax.png";

interface ElaxLogoProps {
  style?: CSSProperties;
}

export const ElaxLogo = ({ style }: ElaxLogoProps) => (
  <img src={logo} alt="logo" style={{ height: 40, ...style }} />
);
