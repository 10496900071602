import { getErrorMessage } from "@elax/utils";
import { Box, Chip, Grid } from "@mui/material";
import {
  FunctionField,
  NumberField,
  ReferenceArrayField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useNotify,
  useRefresh,
} from "react-admin";
import { HubspotLink } from "../../../fields/HubspotLink";
import { InlineLabel } from "../../../layout/InlineLabel";
import { PageTitle } from "../../../layout/PageTitle";
import { RedirectFromHubspotId } from "../../../layout/RedirectFromHubspotId";
import { ActionsMenu, EditAction } from "../../../layout/actions-menu";
import { dataProvider } from "../../../providers/data";
import { ClientCategory } from "../fields/ClientCategory";
import type { Organization } from "../types";
import { AddSftpUser } from "./actions/AddSftpUser";
import { InviteToDashboard } from "./actions/InviteToDashboard";
import { Communications } from "./tabs/Communications";
import { ClientDetails } from "./tabs/Details";
import { ResidenceContacts } from "./tabs/ResidenceContacts";

export const OrganizationShow = () => {
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <RedirectFromHubspotId entity="organizations">
      <Show actions={<OrganizationActions />}>
        <PageTitle />
        <Grid container margin={2}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box marginRight={2}>
              <ClientCategory fontSize="large" />
            </Box>
            <TextField source="name" variant="h4" />
            <Box marginLeft={2}>
              <HubspotLink size={25} source="hubspotURL" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InlineLabel label="Heater Count">
              <TextField source="heaterCount" variant="body1" />
            </InlineLabel>
            <InlineLabel label="Imported Housing Count">
              <NumberField source="stats.housingCount" />
            </InlineLabel>
            <InlineLabel label="Installed Devices Count">
              <NumberField source="stats.deviceCount" />
            </InlineLabel>
            <InlineLabel label="Dashboard Admins">
              <ReferenceArrayField reference="users" source="dashboardAdmins" />
            </InlineLabel>
            <InlineLabel label="Dashboard Viewers">
              <ReferenceArrayField
                reference="users"
                source="dashboardViewers"
              />
            </InlineLabel>
            <InlineLabel label="SFTP users">
              <FunctionField<Organization>
                render={(record) => (
                  <>
                    {(record.sftpUsers ?? []).map((username) => (
                      <Chip
                        size="small"
                        sx={{ margin: 1 }}
                        label={username}
                        key={username}
                        onDelete={async () => {
                          try {
                            await dataProvider.removeSftpUser(
                              record.id,
                              username,
                            );
                            refresh();
                          } catch (error) {
                            notify(
                              `Failed to remove sftp user: ${getErrorMessage(
                                error,
                              )}`,
                              {
                                type: "error",
                              },
                            );
                          }
                        }}
                      />
                    ))}
                  </>
                )}
              />
            </InlineLabel>
          </Grid>
        </Grid>
        <TabbedShowLayout>
          <Tab label="Details">
            <ClientDetails />
          </Tab>
          <Tab label="Communications">
            <Communications />
          </Tab>
          <Tab label="Residence contacts">
            <ResidenceContacts />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </RedirectFromHubspotId>
  );
};

const OrganizationActions = () => {
  return (
    <ActionsMenu>
      <EditAction />
      <InviteToDashboard />
      <AddSftpUser />
    </ActionsMenu>
  );
};
