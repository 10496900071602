import type { TabProps } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from "react-admin";
import type { Except } from "type-fest";
import { JsonParam, useQueryParam, withDefault } from "use-query-params";
import { SyncHubspotButton } from "../../actions/SyncHubspot";
import { HubspotLink } from "../../fields/HubspotLink";
import { DateRangeFilter } from "../../filters/DateRange";
import { useURLFilter } from "../../filters/useURLFilter";
import { MarkdownPanel } from "../../layout/MarkdownPanel";
import { ActionsMenu } from "../../layout/actions-menu";
import { useGetCount } from "../../providers/data-hooks/useGetCount";
import { ProjectContactsField } from "./fields/Contacts";
import { ListingLink } from "./fields/Listing";
import { ProjectDeploymentEndDate } from "./fields/ProjectDeploymentEndDate";
import { ProjectDeploymentStartDate } from "./fields/ProjectDeploymentStartDate";
import { ProjectStage, projectStageLabels } from "./type";

const projectFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    label="Client"
    placeholder="Client"
    source="organizationId"
    reference="organizations"
    sort={{ field: "name", order: "ASC" }}
    perPage={500}
  >
    <AutocompleteInput label="Client" optionText="name" />
  </ReferenceInput>,
  <DateRangeFilter
    label="Deployment Start Date"
    source="deploymentStartDate"
  />,
  <DateRangeFilter label="Deployment End Date" source="deploymentEndDate" />,
  <ReferenceInput
    label="Deployment Maintenance Provider"
    placeholder="Maintenance Provider"
    source="deploymentMaintenanceProviderId"
    reference="maintenance-providers"
    sort={{ field: "name", order: "ASC" }}
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const ProjectList = () => {
  const [stage, setStage] = useURLFilter("stage");

  return (
    <List
      perPage={100}
      sort={{ field: "createdAt", order: "DESC" }}
      filters={projectFilters}
      filter={{ stage: stage ?? ProjectStage.DEPLOYMENT_IN_PROGRESS }}
      actions={
        <>
          <FilterButton />
          <ActionsMenu>
            <SyncHubspotButton />
          </ActionsMenu>
        </>
      }
    >
      <Tabs
        value={stage ?? ProjectStage.DEPLOYMENT_IN_PROGRESS}
        onChange={(_, value) => {
          setStage(value);
        }}
        variant="fullWidth"
      >
        <ProjectStageTab
          stage={ProjectStage.NOT_STARTED_YET}
          value={ProjectStage.NOT_STARTED_YET}
        />
        <ProjectStageTab
          stage={ProjectStage.DEPLOYMENT_PREPARATION}
          value={ProjectStage.DEPLOYMENT_PREPARATION}
        />
        <ProjectStageTab
          stage={ProjectStage.DEPLOYMENT_IN_PROGRESS}
          value={ProjectStage.DEPLOYMENT_IN_PROGRESS}
        />
        <ProjectStageTab
          stage={ProjectStage.DEPLOYMENT_DONE}
          value={ProjectStage.DEPLOYMENT_DONE}
        />
      </Tabs>
      <Datagrid
        bulkActionButtons={false}
        expand={<MarkdownPanel source="notes" />}
        isRowExpandable={(record) => record?.notes}
      >
        <TextField label="Name" source="name" />
        <HubspotLink source="hubspotURL" label="Hubspot" />
        <ListingLink label="Listing" sortBy="lastListingImport.errorCount" />
        <ReferenceField
          label="Client"
          reference="organizations"
          source="organizationId"
          link="show"
        >
          <TextField label="Name" source="name" />
        </ReferenceField>
        <ProjectDeploymentStartDate label="Deployment Start Date" />
        <ProjectDeploymentEndDate label="Deployment End Date" />
        <NumberField label="Housings" source="lastListingImport.housingCount" />
        <ProjectContactsField />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export interface TabWithCountProps extends Except<TabProps, "label"> {
  stage: ProjectStage;
}

export const ProjectStageTab = ({ stage, ...props }: TabWithCountProps) => {
  const [filter] = useQueryParam("filter", withDefault(JsonParam, {}));
  const count = useGetCount("projects", { ...filter, stage });

  return (
    <Tab
      value={stage}
      label={`${projectStageLabels[stage]} (${count ?? "..."})`}
      {...props}
    />
  );
};
