import { Stack, Typography } from "@mui/material";
import { NumberField, useRecordContext } from "react-admin";
import type { NumberFieldProps } from "react-admin";

interface RoundedNumberFieldProps extends NumberFieldProps {
  unit?: string;
}

export const RoundedNumberField = ({
  unit,
  ...props
}: RoundedNumberFieldProps) => {
  const record = useRecordContext();
  return (
    <Stack direction="row">
      <NumberField
        emptyText="-"
        transform={(x) => Math.floor(x * 100) / 100}
        variant="body1"
        {...props}
      />
      {unit && props.source && record?.[props.source] != null && (
        <Typography variant="body1">&nbsp;{unit}</Typography>
      )}
    </Stack>
  );
};
