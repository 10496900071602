import { Grid } from "@mui/material";
import { Edit, SelectInput, SimpleForm, TextInput } from "react-admin";

export const EndUserEditionForm = () => {
  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <SelectInput
            source="title1"
            fullWidth
            sx={{ marginTop: 0 }}
            choices={titleChoices.map((title) => ({
              id: title,
              name: title,
            }))}
          />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="firstName1" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="lastName1" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <SelectInput
            source="title2"
            fullWidth
            sx={{ marginTop: 0 }}
            choices={titleChoices.map((title) => ({ id: title, name: title }))}
          />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="firstName2" fullWidth />
        </Grid>
        <Grid item sm={4}>
          <TextInput source="lastName2" fullWidth />
        </Grid>
        <Grid item sm={12}>
          <TextInput source="notes" fullWidth multiline minRows={4} />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export const EndUserEdit = () => (
  <Edit redirect="show" mutationMode="pessimistic">
    <EndUserEditionForm />
  </Edit>
);

const titleChoices = ["M.", "Mme"];
