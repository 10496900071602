import type { SvgIcon } from "@mui/material";
import type { ReactNode } from "react";
import { activityLogResource } from "./activity-logs";
import { balancingBidResource } from "./balancing-bid";
import { communicationResource } from "./communications";
import { dealResource } from "./deal";
import { demandResponseResource } from "./demand-response";
import { demandResponseEntityResource } from "./demand-response-entities";
import { deviceTagResource } from "./device-tags";
import { deviceResource } from "./devices";
import { edrResource } from "./edr";
import { electricityPriceResource } from "./electricity-prices";
import { endUserResource } from "./end-users";
import { feedbackResource } from "./feedback";
import { firmwareResource } from "./firmware";
import { groupResource } from "./group";
import { heaterResource } from "./heater";
import { housingResource } from "./housing";
import { incidentResource } from "./incidents";
import { interventionResource } from "./intervention";
import { listItemResource } from "./list-item";
import { maintenanceProviderResource } from "./maintenance-provider";
import { organizationResource } from "./organization";
import { projectResource } from "./project";
import { residenceResource } from "./residence";
import { residenceContactResource } from "./residence-contact";
import { userResource } from "./users";

export const resources: Record<
  string,
  {
    icon: typeof SvgIcon;
    options?: { label?: string };
    list?: () => ReactNode;
    edit?: () => ReactNode;
    show?: () => ReactNode;
    create?: () => ReactNode;
    [key: string]: any;
  }
> = {
  communications: communicationResource,
  devices: deviceResource,
  users: userResource,
  groups: groupResource,
  firmwares: firmwareResource,
  "end-users": endUserResource,
  residences: residenceResource,
  "residence-contacts": residenceContactResource,
  housings: housingResource,
  interventions: interventionResource,
  projects: projectResource,
  "electricity-prices": electricityPriceResource,
  heaters: heaterResource,
  feedbacks: feedbackResource,
  organizations: organizationResource,
  "activity-logs": activityLogResource,
  "demand-response-entities": demandResponseEntityResource,
  "demand-responses": demandResponseResource,
  "balancing-bids": balancingBidResource,
  incidents: incidentResource,
  "list-items": listItemResource,
  "device-tags": deviceTagResource,
  "maintenance-providers": maintenanceProviderResource,
  deals: dealResource,
  edrs: edrResource,
};
