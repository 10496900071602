import { Mode } from "@elax/business-types";
import { red } from "@mui/material/colors";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useScheduleData } from "../../../../device-config/useScheduleData";
import { getScheduleSeries } from "../data/charts/schedule-series";

export interface WeeklyInstruction {
  setPoint: number;
  hysteresis: number;
  setPoint2?: number;
  hysteresis2?: number;
  origin: string;
  time: {
    weekDay: number | "*";
    hour: number;
    minute: number;
  };
}

interface ScheduleChartProps {
  weeklyInstructions: WeeklyInstruction[];
}

export const ScheduleChart = ({ weeklyInstructions }: ScheduleChartProps) => {
  const startDate = dayjs().startOf("week").add(1, "day").toDate();
  const endDate = dayjs().endOf("week").add(1, "day").toDate();
  const { data } = useScheduleData({
    config: { mode: Mode.SCHEDULE, parameters: { weeklyInstructions } },
    startDate,
    endDate,
  });
  const scheduleSeries = getScheduleSeries(
    data?.instructions ?? [],
    startDate,
    endDate,
  );

  let schedule2Series: null | any[] = null;
  if (weeklyInstructions[0]?.setPoint2 !== undefined) {
    schedule2Series = getScheduleSeries(
      (data?.instructions ?? []).map(({ instruction, targetDate }) => ({
        instruction: {
          setPoint: instruction.setPoint2 ?? instruction.setPoint,
          hysteresis: instruction.hysteresis2 ?? instruction.hysteresis,
        },
        targetDate,
      })),
      startDate,
      endDate,
    );
  }

  const series = [
    {
      name: "Schedule",
      step: "left",
      type: "line",
      color: "#2196f3",
      data: scheduleSeries,
    },
  ];

  if (schedule2Series) {
    series.push({
      name: "Schedule 2",
      step: "left",
      type: "line",
      color: red["500"],
      data: schedule2Series,
    });
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...chartOptions,
        series,
      }}
    />
  );
};

const chartOptions: Highcharts.Options = {
  chart: {
    height: 200,
  },
  legend: {
    enabled: false,
  },
  title: {
    text: "",
  },
  tooltip: {
    xDateFormat: "%A %H:%M",
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      millisecond: "%A %H:%M:%S.%L",
      second: "%A %H:%M:%S",
      minute: "%A %H:%M",
      hour: "%A %H:%M",
      day: "%A",
      week: "%A",
      month: "%A %b '%y",
      year: "%A %Y",
    },
  },
  yAxis: [
    {
      title: {
        text: "",
      },
    },
  ],
  time: {
    useUTC: false,
  },
};
