import BoltIcon from "@mui/icons-material/Bolt";
import BusinessIcon from "@mui/icons-material/Business";
import CellTowerIcon from "@mui/icons-material/CellTower";
import CodeIcon from "@mui/icons-material/Code";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LeakAddOutlinedIcon from "@mui/icons-material/LeakAddOutlined";
import LocationOn from "@mui/icons-material/LocationOn";
import NetworkFrameIcon from "@mui/icons-material/PermDataSetting";
import RadioIcon from "@mui/icons-material/Radio";
import SignalCellular3BarIcon from "@mui/icons-material/SignalCellular3Bar";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
import mcc_mnc_list from "mcc-mnc-list";
import type { NetworkFrame } from "../../../../../frames/network/type";
import { IconInfo } from "./shared";
import type { FrameSpecs } from "./shared";

export const networkFrameSpecs: FrameSpecs<NetworkFrame> = {
  icon: <NetworkFrameIcon sx={{ color: "#ff9800" }} />,
  data: [
    {
      key: "radioTech" as const,
      label: (frame) => (
        <IconInfo title="LTE-M or NB-IoT" icon={<LeakAddOutlinedIcon />}>
          {frame.radio_tech === 7
            ? "LTE-M"
            : frame.radio_tech === 9
            ? "NB-IoT"
            : frame.radio_tech}
        </IconInfo>
      ),
    },
    {
      key: "frequencyBand" as const,
      label: (frame) => (
        <IconInfo title="Frequency band" icon={<RadioIcon />}>
          {frame.frequency_band}
        </IconInfo>
      ),
    },
    {
      key: "operator" as const,
      label: (frame) => (
        <IconInfo title="Operator" icon={<BusinessIcon />}>
          {mcc_mnc_list.find({ mccmnc: String(frame.operator) })?.operator ??
            frame.operator}
        </IconInfo>
      ),
    },
    {
      key: "rssi" as const,
      label: (frame) => (
        <IconInfo
          title="RSSI (Received Signal Strength)"
          icon={<SignalCellular3BarIcon />}
        >
          {"-" + frame.rssi}
        </IconInfo>
      ),
    },
    {
      key: "rsrp" as const,
      label: (frame) => (
        <IconInfo
          title="RSRP (Reference Signal Receive Power)"
          icon={<BoltIcon />}
        >
          {"-" + frame.rsrp + " dBm"}
        </IconInfo>
      ),
    },
    {
      key: "sinr" as const,
      label: (frame) => (
        <IconInfo
          title="SINR (Signal to Interference plus Noise Ratio)"
          icon={<EqualizerIcon />}
        >
          {Math.round(10 * Math.log10(frame.sinr)) + " dB"}
        </IconInfo>
      ),
    },
    {
      key: "rsrq" as const,
      label: (frame) => (
        <IconInfo
          title="RSRQ (Reference Signal Received Quality)"
          icon={<SpeakerPhoneIcon />}
        >
          {"-" + frame.rsrq + " dB"}
        </IconInfo>
      ),
    },
    {
      key: "lac" as const,
      label: (frame) => (
        <IconInfo title="LAC" icon={<LocationOn />}>
          {frame.lac}
        </IconInfo>
      ),
    },
    {
      key: "cell_id" as const,
      label: (frame) => (
        <IconInfo title="Cell ID" icon={<CellTowerIcon />}>
          {frame.cell_id}
        </IconInfo>
      ),
    },
    {
      key: "modemFirmwareVersion" as const,
      label: (frame) => (
        <IconInfo title="Modem Firmware Version" icon={<CodeIcon />}>
          {frame.modem_firmware_version}
        </IconInfo>
      ),
    },
  ],
};
