export enum DeviceStatus {
  PRODUCTION = "Production",
  STOCK = "Stock",
  USED = "Used",
  OUT_OF_ORDER = "Out of order",
}

export enum DeviceTestGroup {
  DEFAULT = "DEFAULT",
  AUTO_REBOOT = "AUTO_REBOOT",
}
