import { get } from "lodash";
import { FunctionField } from "react-admin";
import type { FunctionFieldProps } from "react-admin";
import type { Except } from "type-fest";

interface EnumFieldProps extends Except<FunctionFieldProps, "render"> {
  colors: Record<string, string>;
  source: string;
  defaultColor?: string;
  label?: string;
  labels?: Record<string, string>;
  emptyText?: string;
  inline?: boolean;
  small?: boolean;
}

export const EnumField = ({
  colors,
  source,
  defaultColor,
  labels,
  emptyText,
  inline,
  small,
  ...props
}: EnumFieldProps) => {
  return (
    <FunctionField
      {...props}
      render={(record: any) => {
        if (!record) return null;
        const value = get(record, source);
        if (emptyText && !value) return emptyText;
        return (
          <div
            style={{
              backgroundColor: (colors[value] || defaultColor) + "52",
              color: colors[value] || defaultColor,
              borderRadius: 5,
              padding: small ? "1px 4px" : "2px 5px",
              fontWeight: "bold",
              display: inline ? "inline-flex" : "flex",
              justifyContent: "center",
              alignItems: "center",
              whiteSpace: "nowrap",
              fontSize: small ? 12 : 14,
            }}
          >
            {labels ? labels[value] ?? value : value}
          </div>
        );
      }}
    />
  );
};
