import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useURLFilter } from "./useURLFilter";

export interface MultiSelectFilterProps {
  source: string;
  label: string;
  options: { label: string; value: string | number }[];
  nestQuery?: boolean;
}

export default function MultiSelectFilter({
  source,
  label,
  options,
  nestQuery,
}: MultiSelectFilterProps) {
  const [value, setValue] = useURLFilter<any>(source, undefined, nestQuery);
  const operator = Object.keys(value ?? {})[0] ?? "$in";

  const arrayValue =
    value && Array.isArray(value[operator]) ? value[operator] : [];

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const selection = event.target.value;
    setValue(selection.length > 0 ? { [operator]: selection } : undefined);
  };

  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel size="small">{label}</InputLabel>
      <Select
        multiple
        value={arrayValue}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        size="small"
        renderValue={(selection) =>
          selection
            .map((value) => options.find((o) => o.value === value)?.label)
            .join(", ")
        }
      >
        <MenuItem
          sx={{
            paddingY: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ToggleButtonGroup
            color="primary"
            value={operator}
            exclusive
            onChange={(e, operator) => {
              setValue({ [operator]: arrayValue ?? [] });
              e.stopPropagation();
            }}
            size="small"
          >
            <ToggleButton value="$in">
              <AddIcon fontSize="small" /> Include
            </ToggleButton>
            <ToggleButton value="$nin">
              <RemoveIcon fontSize="small" /> Exclude
            </ToggleButton>
          </ToggleButtonGroup>
        </MenuItem>
        <Divider />
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value} sx={{ paddingY: 0 }}>
            <Checkbox
              checked={arrayValue.includes(option.value)}
              size="small"
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
