import { Badge } from "@mui/material";
import { orange, red } from "@mui/material/colors";
import { mapValues } from "lodash";
import { FunctionField } from "react-admin";
import { EnumField } from "../../../fields/EnumField";
import type { Incident } from "../types";

interface IncidentTypeFieldProps {
  label?: string;
}

export enum IncidentType {
  NO_HEATING = "no-heating",
  LOST_CONNECTION = "msg-frequency",
  LOST_POWER = "lost-power",
  DEPRECATED_THERMOSTAT = "legionellose-observation",
  BROKEN_PROBE_LOW = "broken-probe-low",
  BROKEN_PROBE_HIGH = "broken-probe-high",
  ELECTRICAL_ISSUE = "electrical-issue",
  SWITCH = "switch",
  TOO_MANY_REBOOTS = "too-many-reboots",
  RELAY_POWER_KO = "relay-power-ko",
}

export const incidentTypeSettings: Record<
  IncidentType,
  { label: string; color: string }
> = {
  [IncidentType.LOST_CONNECTION]: {
    label: "Lost Connection",
    color: "#607d8b",
  },
  [IncidentType.LOST_POWER]: {
    label: "Lost Power",
    color: "#9e9e9e",
  },
  [IncidentType.NO_HEATING]: {
    label: "Heating Failure",
    color: "#673ab7",
  },
  [IncidentType.BROKEN_PROBE_LOW]: {
    label: "Broken Probe (T°C too low)",
    color: "#03a9f4",
  },
  [IncidentType.BROKEN_PROBE_HIGH]: {
    label: "Broken Probe (T°C too high)",
    color: "#03a9f4",
  },
  [IncidentType.DEPRECATED_THERMOSTAT]: {
    label: "Thermostat mécanique trop bas (déprécié)",
    color: "#00bcd4",
  },
  [IncidentType.ELECTRICAL_ISSUE]: {
    label: "Problème électrique partiel",
    color: orange["500"],
  },
  [IncidentType.RELAY_POWER_KO]: {
    label: "Relay Power KO",
    color: orange["500"],
  },
  [IncidentType.SWITCH]: {
    label: "Bypass device button switched on",
    color: "#03a9f4",
  },
  [IncidentType.TOO_MANY_REBOOTS]: {
    label: "Too many Reboots",
    color: red["500"],
  },
};

const colors = mapValues(incidentTypeSettings, ({ color }) => color);
const labels = mapValues(incidentTypeSettings, ({ label }) => label);

export const IncidentTypeField = ({ label }: IncidentTypeFieldProps) => {
  return (
    <FunctionField<Incident>
      label={label ?? "Type"}
      render={(incident) => (
        <Badge
          badgeContent={incident?.device?.alerts.length}
          color={incident?.device?.alerts.length === 0 ? "success" : "error"}
          showZero
        >
          <EnumField
            source="type"
            colors={colors}
            labels={labels}
            emptyText="Missing type"
          />
        </Badge>
      )}
    />
  );
};
