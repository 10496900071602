import { Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  AutocompleteArrayInput,
  Edit,
  ImageField,
  ImageInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { dataProvider } from "../../providers/data";
import { prepareFiles } from "../../providers/file-handler";
import type { Organization } from "./types";

export const OrganizationEdit = () => {
  return (
    <Edit
      transform={prepareFiles<Organization>(["logo"])}
      mutationMode="pessimistic"
      redirect="show"
    >
      <EditionForm />
    </Edit>
  );
};

const EditionForm = () => {
  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: () =>
      dataProvider
        .getList<any>("users", {
          pagination: { perPage: 10_000, page: 1 },
          sort: { field: "email", order: "ASC" },
          filter: {},
        })
        .then(({ data }) => data),
  });

  return (
    <SimpleForm>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <ImageInput
            source="logo"
            label="Logo"
            maxSize={100_000}
            helperText="Max 100kb. Please use transparent PNG or JPEG with white background"
            accept={{ "image/*": [".png", ".jpg", ".jpeg"] }}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Name"
            source="name"
            required={true}
            fullWidth
            disabled
            helperText="This value is filled from Pipedrive data"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Heater Count"
            source="heaterCount"
            fullWidth
            disabled
            helperText="This value is filled from Pipedrive data"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Category"
            source="category"
            fullWidth
            disabled
            helperText="This value is filled from Pipedrive data"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Pipedrive Organisation ID"
            source="pipedriveOrganisationId"
            fullWidth
            disabled
            helperText="This value is filled from Pipedrive data"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutocompleteArrayInput
            label="Dashboard Admins"
            optionText="email"
            source="dashboardAdmins"
            choices={users}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutocompleteArrayInput
            optionText="email"
            label="Dashboard Viewers"
            source="dashboardViewers"
            choices={users}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput label="Maison mère" source="parentCompany" fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
