import { Stack } from "@mui/material";
import { ReferenceInput, SelectInput } from "react-admin";
import { EditFormDialog } from "../../../../../../display/EditFormDialog";
import type { EditFormWithDialogProps } from "../../../../../../display/EditFormDialog";

export const EditEDRDialog = ({ dialogProps }: EditFormWithDialogProps) => {
  return (
    <EditFormDialog
      dialogProps={dialogProps}
      getMutationAxiosConfig={(record) => {
        return {
          method: "PUT",
          url: `/devices/${record?.id}/edr`,
          data: { edrId: record.edrId },
        };
      }}
    >
      <Stack direction="column" spacing={2}>
        <ReferenceInput source="edrId" reference="edrs" label="EDR">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Stack>
    </EditFormDialog>
  );
};
