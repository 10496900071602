import OpenInNew from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import type { ReactNode } from "react";
import { useRecordContext } from "react-admin";
import type { ZendeskTicket } from "../type";

export interface ZendeskTicketUrlFieldProps {
  label?: string;
  text?: ReactNode;
}

export function ZendeskTicketUrlField({
  text = "Open ticket",
}: ZendeskTicketUrlFieldProps): ReactNode {
  const record = useRecordContext<ZendeskTicket>();

  if (!record) {
    return null;
  }

  return (
    <Button
      variant="text"
      href={record.url}
      target="_blank"
      startIcon={<OpenInNew />}
      sx={{ marginLeft: 2 }}
    >
      {text}
    </Button>
  );
}
