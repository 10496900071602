import { useGetList } from "react-admin";
import type { Except } from "type-fest";
import type { ListItem } from "../resources/list-item/types";
import MultiSelectFilter from "./MultiSelectFilter";
import type { MultiSelectFilterProps } from "./MultiSelectFilter";

interface ListItemFilterProps
  extends Except<MultiSelectFilterProps, "options"> {
  listId: any;
}

export const ListItemFilter = ({ listId, ...props }: ListItemFilterProps) => {
  const { data: listItems } = useGetList<ListItem>("list-items", {
    pagination: { page: 1, perPage: 1000 },
    filter: { listId },
    sort: { order: "ASC", field: "value" },
  });

  return (
    <MultiSelectFilter
      {...props}
      options={
        listItems?.map((item) => ({
          value: item.value,
          label: item.label ?? item.value,
        })) ?? []
      }
    />
  );
};
