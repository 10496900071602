import { EditInDialogButton } from "@react-admin/ra-form-layout";
import {
  Datagrid,
  DeleteButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { ListItemForm } from "./inputs/ListItemForm";

export const ListItemList = () => {
  return (
    <List perPage={100} filters={[<TextInput source="listId" label="List" />]}>
      <Datagrid bulkActionButtons={false}>
        <TextField label="List" source="listId" />
        <TextField label="Value" source="value" />
        <TextField label="Label" source="label" />
        <EditInDialogButton>
          <SimpleForm>
            <ListItemForm />
          </SimpleForm>
        </EditInDialogButton>
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
