import { ReferenceField, TextField } from "react-admin";
import InfoList from "../../InfoList";
import { EditEDRDialog } from "./EditDialog";

export const EDRField = () => {
  return (
    <InfoList.Item label="EDR" editDialog={<EditEDRDialog />}>
      <ReferenceField reference="edrs" source="edrId">
        <TextField source="name" />
      </ReferenceField>
    </InfoList.Item>
  );
};
