import { IncidentStatus, Mode } from "@elax/business-types";
import FalsePositiveIcon from "@mui/icons-material/Cancel";
import TimerIcon from "@mui/icons-material/Timer";
import dayjs from "dayjs";
import { BooleanInput, SearchInput, SelectInput } from "react-admin";
import { DateRangeFilter } from "../../../../filters/DateRange";
import { EnumFilter } from "../../../../filters/EnumFilter";
import { FixedValueFilter } from "../../../../filters/FixedValueFilter";
import { ListItemFilter } from "../../../../filters/ListItemFilter";
import MultiSelectFilter from "../../../../filters/MultiSelectFilter";
import { MultiSelectReferenceFilter } from "../../../../filters/MultiSelectReferenceFilter";
import { RecentDateFilter } from "../../../../filters/RecentDateFilter";
import { contractTypeLabels } from "../../../devices/fields/ContractTypeField";
import { SelectProject } from "../../../devices/fields/SelectProject";
import { InterventionStatus } from "../../../intervention/types";
import { incidentTypeSettings } from "../../fields/IncidentTypeField";

export const incidentFilters = [
  <SearchInput source="q" alwaysOn />,
  <MultiSelectFilter
    label="Priority"
    source="priority"
    options={[1, 2, 3, 4].map((value) => ({
      label: "P" + value.toString(),
      value,
    }))}
  />,
  <SelectProject source="projectId" label="Project" />,
  <FixedValueFilter
    label="Deployment in progress"
    source="project"
    defaultValue={{
      deploymentEndDate: { $gte: dayjs().format("YYYY-MM-DD") },
    }}
  >
    <TimerIcon fontSize="small" />
    Deployment&nbsp;in&nbsp;progress
  </FixedValueFilter>,
  <MultiSelectFilter
    label="Type"
    source="type"
    options={Object.entries(incidentTypeSettings).map(([value, { label }]) => ({
      label,
      value,
    }))}
  />,
  <ListItemFilter
    label="Category"
    source="category"
    listId={{ $regex: "^Incident category for" }}
  />,
  <ListItemFilter
    label="Action"
    source="action"
    nestQuery={false}
    listId={{ $regex: "^Incident actions for" }}
  />,
  <SelectInput
    label="Pilot Mode"
    source="device.mode"
    choices={Object.values(Mode).map((t) => ({ id: t, name: t }))}
  />,
  <EnumFilter
    label="Hardware Version"
    source="device.VersionHardware"
    categories={[
      { label: "1", value: { $regex: "^1" } },
      { label: "2", value: { $regex: "^2." } },
    ]}
  />,
  <MultiSelectReferenceFilter
    reference="device-tags"
    label="Device Tags"
    source="device.tags"
    optionText="name"
  />,
  <DateRangeFilter source="createdAt" label="Start Date" />,
  <DateRangeFilter source="updatedAt" label="Last Update" />,
  <RecentDateFilter
    source="installationDate"
    label="Installed in the last X days"
  />,
  <FixedValueFilter
    label="False Positive"
    source="isFalsePositive"
    defaultValue={true}
  >
    <FalsePositiveIcon fontSize="small" />
    False Positive
  </FixedValueFilter>,
  <BooleanInput label="No Resident" source="noResident" defaultValue={false} />,
  <EnumFilter
    label="Contract Type"
    source="device.contractType"
    categories={Object.entries(contractTypeLabels).map(([value, label]) => ({
      value,
      label,
    }))}
  />,
  <MultiSelectReferenceFilter
    reference="maintenance-providers"
    label="Maintenance Providers"
    source="maintenanceProviderId"
    optionText="name"
  />,
  <SelectInput
    label="Maintenance status"
    source="maintenanceStatus"
    choices={Object.values(InterventionStatus).map((v) => ({ id: v, name: v }))}
  />,
  <EnumFilter
    label="Status"
    source="status"
    categories={Object.values(IncidentStatus).map((status) => ({
      label: status,
      value: status,
    }))}
  />,
];
