import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";
import { SatisfiedField } from "./fields/Satisfied";

export const FeedbackList = () => {
  return (
    <List perPage={100}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField label="type" source="type" />
        <ReferenceField reference="devices" source="deviceID" label="Device">
          <TextField source="name" />
        </ReferenceField>
        <SatisfiedField source="data.satisfied" label="Satisfied" />
        <TextField source="data.comment" label="Comment" />
        <DateField label="Date" source="createdAt" />
      </Datagrid>
    </List>
  );
};
