export enum HousingStatus {
  NOT_EQUIPPED = "NOT_EQUIPPED",
  EQUIPPED = "EQUIPPED",
  TAKEN_OFF = "TAKEN_OFF",
  NOT_EQUIPPABLE = "NOT_EQUIPPABLE",
}

export const housingStatusLabels: Record<HousingStatus, string> = {
  [HousingStatus.NOT_EQUIPPABLE]: "non équipable",
  [HousingStatus.NOT_EQUIPPED]: "non équipé",
  [HousingStatus.EQUIPPED]: "équipé",
  [HousingStatus.TAKEN_OFF]: "démonté",
};

export const housingStatusColors: Record<HousingStatus, string> = {
  [HousingStatus.NOT_EQUIPPED]: "#795548",
  [HousingStatus.NOT_EQUIPPABLE]: "#607d8b",
  [HousingStatus.EQUIPPED]: "#4caf50",
  [HousingStatus.TAKEN_OFF]: "#f44336",
};

export interface Housing {
  id: string;
  unitId: string;
  residenceId: string;
  status: HousingStatus;
  address: string;
  additionalAddress?: string;
  zipCode: string;
  city: string;
  createdAt: Date;
  updatedAt: Date;
  pdl?: string;
}
