import type { ReactJsonViewProps } from "@microlink/react-json-view";
import ReactJson from "@microlink/react-json-view";
import type { ReactNode } from "react";
import { useRecordContext } from "react-admin";
import type { FieldProps } from "react-admin";
import type { Except } from "type-fest";

interface JsonFieldProps extends FieldProps {
  source: string;
  jsonString?: boolean;
  reactJsonOptions?: Except<ReactJsonViewProps, "src">;
}

export function JsonField({
  source,
  jsonString = false,
  reactJsonOptions = {},
}: JsonFieldProps): ReactNode {
  const record = useRecordContext();

  let src = record?.[source];

  if (jsonString && src) {
    src = JSON.parse(src);
  }

  return <ReactJson {...reactJsonOptions} src={src || {}} />;
}
