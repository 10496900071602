import SyncIcon from "@mui/icons-material/Sync";
import { useNotify } from "react-admin";
import { ActionMenuItem } from "../layout/actions-menu";
import { useAxiosMutation } from "../providers/data-hooks/useAxiosQuery";

export const SyncHubspotButton = () => {
  const notify = useNotify();

  const { mutate: syncHubspot } = useAxiosMutation(
    {
      method: "PUT",
      url: "/hubspot/sync",
    },
    {
      onSuccess: () => {
        notify("Synchronisation in progress...");
      },
      onError: (error) => {
        notify(`Synchronisation error: ${error}`, { type: "error" });
      },
    },
  );

  return (
    <ActionMenuItem
      label="Sync Hubspot"
      icon={<SyncIcon />}
      onClick={() => {
        syncHubspot();
      }}
    />
  );
};
