import PeopleIcon from "@mui/icons-material/People";
import { DialogContent, DialogTitle } from "@mui/material";
import {
  CreateContextProvider,
  useCreateController,
  useGetManyReference,
  useRecordContext,
} from "react-admin";
import {
  ActionDialog,
  DialogActionMenuItem,
} from "../../../layout/actions-menu";
import type { Residence } from "../../residence/types";
import { ResidenceContactEditionForm } from "../../residence-contact/Edit";
import type { Project } from "../type";

export const CreateResidenceContact = () => {
  const project = useRecordContext<Project>();
  const { data: residences } = useGetManyReference<Residence>("residences", {
    target: "projects.projectId",
    id: project?.id ?? "",
    pagination: { page: 1, perPage: 10_000 },
  });

  const createControllerProps = useCreateController({
    resource: "residence-contacts",
    record: {
      residences: residences?.map((residence) => residence.id),
      organizationId: project?.organizationId,
    },
  });

  if (!project) return null;

  return (
    <DialogActionMenuItem
      label="Create residence contact"
      icon={<PeopleIcon />}
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>Create residence contact</DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <CreateContextProvider value={createControllerProps}>
              <ResidenceContactEditionForm />
            </CreateContextProvider>
          </DialogContent>
        </ActionDialog>
      }
    />
  );
};
