import { Grid } from "@mui/material";
import {
  AutocompleteInput,
  Create,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { DatetimeInput } from "./fields/DatetimeInput";
import { DeviceInput } from "./fields/DeviceInput";
import { DeviceLocationAfterRemovalInput } from "./fields/DeviceLocationAfterRemovalInput";
import { InterventionSourcingStatusInput } from "./fields/InterventionSourcingStatusInput";
import { InterventionStatusInput } from "./fields/InterventionStatusInput";
import { InterventionTypeInput } from "./fields/InterventionTypeInput";
import { InterventionVisitStatusInput } from "./fields/InterventionVisitStatusInput";
import { RemovalResponsibleInput } from "./fields/RemovalResponsibleInput";
import { ReplacedDeviceInput } from "./fields/ReplacedDeviceInput";
import { interventionPriorityLabels } from "./types";
import type { Intervention, InterventionPriority } from "./types";

export const InterventionEdit = () => (
  <Edit mutationMode="pessimistic" redirect={false}>
    <SimpleForm>
      <InterventionEditionForm />
    </SimpleForm>
  </Edit>
);

export interface InterventionCreateProps {
  initialValues?: Partial<Intervention>;
}

export const InterventionCreate = ({
  initialValues,
}: InterventionCreateProps = {}) => (
  <Create record={initialValues}>
    <SimpleForm>
      <InterventionEditionForm />
    </SimpleForm>
  </Create>
);

function InterventionEditionForm() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InterventionTypeInput />
      </Grid>
      <Grid item xs={12}>
        <RemovalResponsibleInput />
      </Grid>
      <Grid item xs={12}>
        <DeviceLocationAfterRemovalInput />
      </Grid>
      <Grid item xs={12}>
        <InterventionStatusInput />
      </Grid>
      <Grid item xs={12}>
        <InterventionSourcingStatusInput />
      </Grid>
      <Grid item xs={12}>
        <InterventionVisitStatusInput />
      </Grid>
      <Grid item xs={12}>
        <ReplacedDeviceInput />
      </Grid>
      <Grid item xs={12}>
        <DeviceInput />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          reference="housings"
          source="housingId"
          fullWidth
          perPage={1000}
        >
          <AutocompleteInput label="Housing" optionText="unitId" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          reference="projects"
          source="projectId"
          fullWidth
          perPage={1000}
        >
          <AutocompleteInput label="Project" optionText="name" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <DatetimeInput />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Kizeo ID" source="externalId" fullWidth />
      </Grid>
      <Grid item xs={12}>
        <ReferenceInput
          reference="maintenance-providers"
          source="maintenanceProviderId"
          fullWidth
          perPage={1000}
        >
          <AutocompleteInput
            label="Maintenance Provider"
            optionText="name"
            fullWidth
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Mission Order"
          source="missionOrder"
          required={false}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          source="priority"
          label="Priority"
          choices={[1, 2, 3, 4].map((value) => ({
            id: value,
            name: interventionPriorityLabels[value as InterventionPriority],
          }))}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput label="Comment" source="comment" required={true} fullWidth />
      </Grid>
    </Grid>
  );
}
