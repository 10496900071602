import { getErrorMessage } from "@elax/utils";
import Icon from "@mui/icons-material/Home";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import dayjs from "dayjs";
import {
  DateInput,
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import { DeviceLocationAfterRemovalInput } from "../../../intervention/fields/DeviceLocationAfterRemovalInput";
import { RemovalResponsibleInput } from "../../../intervention/fields/RemovalResponsibleInput";
import { InterventionType } from "../../../intervention/types";
import type { Device } from "../../types";

interface UninstallBody {
  date: string;
  comment: string;
  removalResponsible: string;
  deviceLocationAfterRemoval: string;
}

export const UninstallButton = () => {
  const record = useRecordContext<Device>();
  const refresh = useRefresh();
  const notify = useNotify();

  const uninstall = async (values: UninstallBody, callback: () => void) => {
    try {
      if (!record) throw new Error("Can't uninstall an unknown device");
      await dataProvider.uninstallDevice(record.id, values);
      callback();
      notify("Device uninstalled", { type: "success" });
      refresh();
    } catch (error) {
      notify(`Failed to uninstall device: ${getErrorMessage(error)}`, {
        type: "error",
      });
    }
  };

  return (
    <DialogActionMenuItem
      label="Uninstall"
      icon={<Icon />}
      dialog={(closeDialog) => (
        <ActionDialog fullWidth>
          <Form
            sanitizeEmptyValues
            onSubmit={(values: any) =>
              uninstall(values as UninstallBody, closeDialog)
            }
            defaultValues={{
              type: InterventionType.REMOVAL,
            }}
          >
            <DialogTitle>Uninstall device</DialogTitle>
            <DialogContent>
              <DateInput
                source="date"
                label="Intervention Date"
                defaultValue={dayjs().format("YYYY-MM-DD")}
                fullWidth
                sx={{ marginTop: 2 }}
                required
              />
              <RemovalResponsibleInput required />
              <DeviceLocationAfterRemovalInput />
              <TextInput
                label="Comment"
                source="comment"
                fullWidth
                multiline
                minRows={3}
                required
              />
            </DialogContent>
            <DialogActions>
              <ActionDialogButton>Cancel</ActionDialogButton>
              <ActionDialogButton
                variant="contained"
                type="submit"
                onClick={() => {}}
              >
                Uninstall
              </ActionDialogButton>
            </DialogActions>
          </Form>
        </ActionDialog>
      )}
    />
  );
};
