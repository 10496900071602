import type { ReactNode } from "react";
import { useEffect } from "react";
import { useGetList, useNotify } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";

interface RedirectIMEIProps {
  entity: "organizations" | "projects" | "deals";
  children: ReactNode;
}

export const RedirectFromHubspotId = ({
  entity,
  children,
}: RedirectIMEIProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const notify = useNotify();

  const isHubspotId = Boolean(
    params.id &&
      !Number.isNaN(+params.id) &&
      (params.id.length === 11 || params.id.length === 12),
  );

  const { data } = useGetList(
    entity,
    { filter: { hubspotId: params.id }, pagination: { page: 1, perPage: 1 } },
    { enabled: isHubspotId },
  );

  useEffect(() => {
    if (!isHubspotId || !data) {
      return;
    }
    if (data.length === 0) {
      navigate(`/${entity}`);
      notify("Element does not exists", { type: "error" });
      return;
    }
    navigate(`/${entity}/${data[0].id}/show`, { replace: true });
  }, [navigate, notify, data, entity, isHubspotId]);

  if (isHubspotId) {
    return null;
  }

  return children;
};
