import { SelectInput } from "react-admin";
import { interventionPriorityLabels } from "../../../intervention/types";
import type { InterventionPriority } from "../../../intervention/types";

interface PriorityInputProps {
  source: string;
}

export const PriorityInput = ({ source }: PriorityInputProps) => {
  return (
    <SelectInput
      fullWidth
      label="Priority"
      source={source}
      choices={[1, 2, 3, 4].map((value) => ({
        id: value,
        name: interventionPriorityLabels[value as InterventionPriority],
      }))}
    />
  );
};
