import { IncidentActor, IncidentStatus } from "@elax/business-types";
import type { Color } from "@mui/material";
import {
  amber,
  blue,
  blueGrey,
  cyan,
  deepPurple,
  green,
  lightGreen,
  lime,
  pink,
  purple,
  red,
  teal,
} from "@mui/material/colors";
import type { RaRecord } from "react-admin";
import type { InterventionAccounting } from "../intervention/types";
import type { IncidentType } from "./fields/IncidentTypeField";

export type Incident = {
  id: string;
  deviceId: string;
  status: IncidentStatus;
  type: IncidentType;
  assignedTo: IncidentActor;
  events: IncidentEvent[];
  tags: string[];
  disabledAutoUpdate?: boolean;
  category?: string;
  dueDate?: Date;
  isFalsePositive?: boolean;
  priority?: 1 | 2 | 3 | 4;
  action?: string;
  comment?: string;
  maintenanceProviderId?: string;
  interventionAccounting?: InterventionAccounting;
  missionOrder?: string;
} & RaRecord;

export const incidentTag: Record<
  string,
  { label: string; description: string; color: Color }
> = {
  NO_RESIDENT_CONTACT: {
    label: "Résident injoignable",
    color: red,
    description: "Impossible de contacter le résident",
  },
  COMMUNICATION_BUG: {
    label: "Bug communication",
    color: green,
    description:
      "Problème de communication du boîtier qui perturbe les alertes et l'algo",
  },
  MISSING_CONTACT: {
    label: "Coordonnées manquantes",
    color: blue,
    description:
      "Actions nécessaires pour les CSM => contact auprès du bailleur",
  },
  PILOTABLE_HEATER: {
    label: "Chauffe-eau pilotable",
    color: blueGrey,
    description:
      "Le résident peut éteindre le chauffe-eau au niveau du ballon (après notre boîtier) = Notre boîtier n'est pas éteint et des alertes remontent. Le résident peut gérer la température du chauffe-eau manuellement",
  },
  PILOT_REFUSAL: {
    label: "Refus du pilotage",
    color: lightGreen,
    description:
      "Le résident refuse que l'on pilote la consommation du chauffe-eau",
  },
  LANDLORD_MANAGEMENT: {
    label: "Gestion bailleur",
    color: pink,
    description:
      "Le bailleur gère à la place du locataire l'installation et les maintenances Il faudra donc le contacter directement pour tous les besoins OPS liés au device",
  },
};

export const incidentActorLabels: Record<
  IncidentActor,
  { color: Color; label: string }
> = {
  [IncidentActor.MAINTENANCE_PROVIDER]: {
    color: teal,
    label: "Maintainer",
  },
  [IncidentActor.RESIDENT]: {
    color: lime,
    label: "Resident",
  },
  [IncidentActor.LANDLORD]: {
    color: cyan,
    label: "Landlord",
  },
  [IncidentActor.DATA_TEAM]: {
    color: deepPurple,
    label: "Data",
  },
  [IncidentActor.TECH_TEAM]: { color: purple, label: "Dev" },
  [IncidentActor.OPS_TEAM]: { color: red, label: "Ops" },
  [IncidentActor.UNIDENTIFIED]: { color: green, label: "Sandbox" },
};

export type IncidentEvent = {
  datetime: Date;
  origin: string;
  alertId: string;
  userId?: string;
} & Pick<
  Incident,
  | "action"
  | "assignedTo"
  | "comment"
  | "status"
  | "priority"
  | "category"
  | "maintenanceProviderId"
  | "interventionAccounting"
>;

export const incidentStatusColors: Record<IncidentStatus, string> = {
  [IncidentStatus.OPENED]: amber[400],
  [IncidentStatus.CLOSED]: blue[400],
};
