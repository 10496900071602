import { getErrorMessage } from "@elax/utils";
import Icon from "@mui/icons-material/PersonAdd";
import {
  Alert,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useNotify, useRecordContext } from "react-admin";
import {
  ActionDialog,
  ActionDialogButton,
  DialogActionMenuItem,
} from "../../../../layout/actions-menu";
import { dataProvider } from "../../../../providers/data";
import type { Organization } from "../../types";

export const InviteToDashboard = () => {
  const record = useRecordContext<Organization>();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("viewer");
  const [sendEmail, setSendEmail] = useState(true);
  const notify = useNotify();
  // const refresh = useRefresh()
  // const key = useRef(Math.random())

  const {
    mutate: invite,
    isPending,
    data,
  } = useMutation({
    mutationKey: ["/invite"],
    mutationFn: () => {
      return dataProvider.invite(
        email,
        "Landlord",
        record?.id,
        role,
        sendEmail,
      );
    },
    onSuccess: () => {
      if (sendEmail) {
        notify("Invitation email sent");
      }
    },
    onError: (error: unknown) => {
      notify(`Failed to send invitation: ${getErrorMessage(error)}`, {
        type: "error",
      });
    },
  });

  if (!record) {
    return null;
  }

  return (
    <DialogActionMenuItem
      label="Invite to dashboard"
      icon={<Icon />}
      dialog={
        <ActionDialog fullWidth>
          <DialogTitle>Invite to dashboard</DialogTitle>
          <DialogContent>
            {!record.logo?.src && (
              <Alert severity="error" sx={{ marginBottom: 3 }}>
                A logo is required to enable the dashboard
              </Alert>
            )}
            <TextField
              multiline
              label="Email"
              fullWidth
              value={email}
              autoFocus
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <Select
              value={role}
              sx={{ marginY: 2 }}
              fullWidth
              label="Role"
              onChange={(event) => {
                setRole(event.target.value);
              }}
            >
              <MenuItem value="viewer">Viewer</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmail}
                  onChange={(_, v) => setSendEmail(v)}
                />
              }
              label="Send invitation email"
            />
            {data && (
              <Alert severity="success">
                Invitation link is app.elaxenergy.com/invite?token={data}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <ActionDialogButton>Cancel</ActionDialogButton>
            <ActionDialogButton
              variant="contained"
              disabled={isPending || !record.logo?.src}
              onClick={(_, onClose) => {
                if (sendEmail) {
                  onClose();
                }
                invite();
              }}
            >
              Invite
            </ActionDialogButton>
          </DialogActions>
        </ActionDialog>
      }
    />
  );
};
