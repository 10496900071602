import EmailIcon from "@mui/icons-material/Email";
import { DialogActionMenuItem } from "../../../../layout/actions-menu";
import { SendInfoToMaintenanceProviderDialog } from "./SendInfoToMaintenanceProviderDialog";

export const SendInfoToMaintenanceProviderButton = () => {
  return (
    <DialogActionMenuItem
      label="Send info to maintenance provider"
      icon={<EmailIcon />}
      dialog={<SendInfoToMaintenanceProviderDialog />}
    />
  );
};
