import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const TemporaryEventsTab = () => {
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        reference="device-temporary-events"
        target="deviceId"
        sort={{ field: "startDate", order: "DESC" }}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Type" source="type" />
          <DateField label="Start Date" source="startDate" showTime />
          <DateField label="End Date" source="endDate" showTime emptyText="-" />
          <DateField
            label="Cancelled At"
            source="cancelledAt"
            showTime
            emptyText="-"
          />
          <DateField label="Created At" source="createdAt" showTime />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};
