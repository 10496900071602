import { Box, Stack } from "@mui/material";
import {
  Datagrid,
  Pagination,
  ReferenceManyField,
  Show,
  TextField,
} from "react-admin";
import { EDRIcon } from "..";

export const EDRShow = () => {
  return (
    <Show>
      <Box padding={2}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <EDRIcon fontSize="large" />
          <TextField source="name" variant="h4" />
        </Stack>
        <ReferenceManyField
          reference="devices"
          target="edrId"
          label="devices"
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField source="IMEI" />
            <TextField source="status" />
          </Datagrid>
        </ReferenceManyField>
      </Box>
    </Show>
  );
};
