import { useGetList } from "react-admin";
import MultiSelectFilter from "./MultiSelectFilter";

interface MultiSelectReferenceFilterProps {
  reference: string;
  label: string;
  source: string;
  optionText: string;
}

export const MultiSelectReferenceFilter = ({
  reference,
  source,
  label,
  optionText,
}: MultiSelectReferenceFilterProps) => {
  const { data } = useGetList(reference, {
    pagination: { page: 1, perPage: 1000 },
  });

  return (
    <MultiSelectFilter
      source={source}
      label={label}
      options={
        data?.map((item) => ({ label: item[optionText], value: item.id })) ?? []
      }
    />
  );
};
