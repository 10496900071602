import axios, { AxiosHeaders } from "axios";
import type { AxiosError } from "axios";
import dayjs from "dayjs";
import type { DataProvider, PaginationPayload, SortPayload } from "react-admin";
import { API_BASE_URL } from "../config";
import type { DemandResponse } from "../resources/demand-response/types";
import { getToken, getUnverifiedTokenPayload } from "./auth";

export const axiosClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json",
  },
  withCredentials: true,
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = getToken();
    if (token) {
      config.headers = new AxiosHeaders({
        ...config.headers,
        Authorization: token && `Bearer ${token}`,
        "Elax-App-Pathname": window.location.hash,
      });
    }
    return config;
  },
  null,
  { synchronous: true },
);

axiosClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError<any>) => {
    error.message = error.response?.data?.message || error.message;
    return Promise.reject(error);
  },
);

export const getBlob = async (route: string, mime: string) => {
  const res = await axiosClient
    .get(route, {
      responseType: "arraybuffer",
      headers: {
        Accept: mime,
      },
    })
    .catch((error) => {
      throw new Error(
        error.response?.data
          ? JSON.parse(new TextDecoder().decode(error.response.data)).message
          : error.message,
      );
    });

  return new Blob([res.data], { type: mime });
};

export const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
};

const getStringifiedRange = (
  resource: string,
  pagination: PaginationPayload | undefined,
) => {
  if (!pagination) {
    return { rangeParam: undefined, rangeHeader: undefined };
  }
  const { page, perPage } = pagination;
  const rangeStart = (page - 1) * perPage;
  const rangeEnd = page * perPage - 1;

  return {
    rangeParam: JSON.stringify([rangeStart, rangeEnd]),
    rangeHeader: `${resource}=${rangeStart}-${rangeEnd}`,
  };
};

const getStringifiedSort = (sort: SortPayload | undefined) => {
  if (!sort) {
    return undefined;
  }
  const { field, order } = sort;

  return JSON.stringify([field, order]);
};

const getList: DataProvider["getList"] = async (resource, params) => {
  const { rangeParam, rangeHeader } = getStringifiedRange(
    resource,
    params.pagination,
  );
  const { data, headers } = await axiosClient.get(`/${resource}`, {
    params: {
      sort: getStringifiedSort(params.sort),
      range: rangeParam,
      filter: JSON.stringify(params.filter),
      ...params.meta,
    },
    // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
    headers: new AxiosHeaders(
      rangeHeader
        ? {
            Range: rangeHeader,
          }
        : {},
    ),
  });

  return {
    data,
    total: Number.parseInt(headers["content-range"].split("/").pop(), 10),
  };
};

const getManyReference: DataProvider["getManyReference"] = async (
  resource,
  params,
) =>
  getList(resource, {
    ...params,
    filter: { ...params.filter, [params.target]: params.id },
  });

const getOne: DataProvider["getOne"] = async (resource, params) =>
  axiosClient.get(`/${resource}/${params.id}`);

const getMany: DataProvider["getMany"] = async (resource, params) => {
  return axiosClient.get(`/${resource}`, {
    params: {
      filter: JSON.stringify({ id: params.ids }),
      ...params.meta,
    },
  });
};

const update: DataProvider["update"] = async (resource, params) =>
  axiosClient.put(`/${resource}/${params.id}`, params.data);

const updateMany: DataProvider["updateMany"] = async (resource, params) =>
  Promise.all(
    params.ids.map((id) => axiosClient.put(`/${resource}/${id}`, params.data)),
  ).then((responses) => ({ data: responses.map(({ data }) => data.id) }));

const create: DataProvider["create"] = (resource, params) =>
  axiosClient.post(`/${resource}`, params.data);

const deleteOne: DataProvider["delete"] = (resource, params) =>
  axiosClient.delete(`/${resource}/${params.id}`, {
    headers: new AxiosHeaders({
      "Content-Type": "text/plain",
    }),
  });

const deleteMany: DataProvider["deleteMany"] = (resource, params) =>
  Promise.all(
    params.ids.map((id) =>
      axiosClient.delete(`/${resource}/${id}`, {
        headers: new AxiosHeaders({
          "Content-Type": "text/plain",
        }),
      }),
    ),
  ).then((responses) => ({
    data: responses.map(({ data }) => data.id),
  }));

export const dataProvider = {
  getList,
  getOne,
  getMany,
  getManyReference,
  update,
  updateMany,
  create,
  delete: deleteOne,
  deleteMany,
  bulkUpdate: (resource: string, filter: any, body: any) => {
    return axiosClient.put(`/${resource}`, body, {
      params: { filter: JSON.stringify(filter) },
    });
  },
  setPDLs: (
    entity: string,
    action: "ADD" | "SET" | "REMOVE",
    pdls: string[],
  ) => {
    return axiosClient.put(`/demand-response-entities/${entity}/pdls`, {
      action,
      pdls,
    });
  },
  setPassword: (password: string) =>
    axiosClient.put(`${API_BASE_URL.replace("/admin", "")}/user/password`, {
      password,
    }),
  forgotPassword: (email: string) =>
    axiosClient.post(
      `${API_BASE_URL.replace("/admin", "")}/user/reset-password`,
      {
        email,
      },
    ),
  search: (q: string) =>
    axiosClient
      .get(`/search?q=${encodeURIComponent(q)}`)
      .then((result) => result.data),
  isDeviceObserving: (IMEI: number) =>
    axiosClient.get(`/devices/${IMEI}/observe`).then((result) => result.data),
  copyDevice: (
    deviceId: string,
    body: { deviceName: string; toCopy: string[]; from: Date; to: Date },
  ) => axiosClient.put(`/devices/${deviceId}/copy`, body),
  importProject: (projectId: string) => {
    return axiosClient
      .put(`/projects/${projectId}/import`)
      .then((response) => response.data);
  },
  sendConfigFlags: (
    deviceId: string,
    flag:
      | "ReqStatus"
      | "ReqHardReset"
      | "ReqFota"
      | "ReqTest"
      | "ReqRecovery"
      | "ReqDebug"
      | "ReqCertServ"
      | "ReqCertDev",
  ) => axiosClient.post(`/devices/${deviceId}/config-flags`, { flag }),
  syncKizeo: (interventionId: string) =>
    axiosClient
      .put(`/interventions/${interventionId}/kizeo-sync`)
      .then((response) => response.data),
  syncProjects: () =>
    axiosClient.put("/projects/sync").then((response) => response.data),
  resetPassword: () =>
    axiosClient.put("/users/reset-password").then((response) => response.data),
  syncAllKizeo: () =>
    axiosClient.put("/trigger-kizeo-job").then((response) => response.data),
  saveUserConfig: (values: Record<string, any>) => {
    const payload = getUnverifiedTokenPayload();
    return axiosClient
      .put(`/users/${payload._id}`, { backOfficeConfig: values })
      .then((response) => response.data);
  },
  applyIntervention: async (id: string) =>
    axiosClient.put(`/interventions/${id}/apply`),
  invite: async (
    email: string,
    role: string,
    organizationId?: string,
    organizationRole?: string,
    sendEmail?: boolean,
  ) => {
    const { data } = await axiosClient.post<{ token: string }>(
      `${API_BASE_URL.replace("/admin", "")}/user/invite`,
      {
        email,
        role,
        organizationId,
        organizationRole,
        sendEmail,
      },
    );
    return data.token;
  },
  addSftpUser: async (organizationId: string, username: string) => {
    const { data } = await axiosClient.post<{ password: string }>(
      `/organization/${organizationId}/sftp-user`,
      {
        username,
      },
    );
    return data.password;
  },
  removeSftpUser: async (organizationId: string, username: string) => {
    await axiosClient.delete<{ password: string }>(
      `/organization/${organizationId}/sftp-user/${username}`,
    );
  },
  exportFrames: async (filter: any, startDate: string, endDate: string) => {
    const { data } = await axiosClient.get("/frames/export", {
      params: {
        filter: JSON.stringify(filter),
        startDate: dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss"),
        endDate: dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss"),
      },
    });
    return data;
  },
  getDemandResponses: (deviceId: string, startDate: Date, endDate: Date) =>
    axiosClient
      .get<Pick<DemandResponse, "id" | "startDate" | "endDate" | "type">[]>(
        `/devices/${deviceId}/demand-responses`,
        {
          params: { startDate, endDate },
        },
      )
      .then((response) => response.data),
  installDevice: (
    deviceId: string,
    housingId: string,
    date: string,
    comment: string,
  ) =>
    axiosClient
      .put(`/devices/${deviceId}/install`, { date, comment, housingId })
      .then((response) => response.data),
  uninstallDevice: (
    id: string,
    body: {
      date: string;
      comment: string;
      removalResponsible: string;
      deviceLocationAfterRemoval: string;
    },
  ) =>
    axiosClient
      .put(`/devices/${id}/uninstall`, body)
      .then((response) => response.data),
  declareMoving: (housingId: string) =>
    axiosClient
      .put(`/housings/${housingId}/moving`)
      .then((response) => response.data),
  replaceDevice: ({
    replacedDeviceId,
    deviceId,
    date,
    comment,
  }: {
    replacedDeviceId: string;
    deviceId: string;
    date: string;
    comment: string;
  }) =>
    axiosClient
      .put(`/devices/${replacedDeviceId}/replace`, { deviceId, date, comment })
      .then((response) => response.data),
  getInterventionAnalytics: () =>
    axiosClient
      .get<{
        data: { installations: any[]; usedDevicesCount: number };
        lastUpdateDate: string;
      }>("/interventions/analytics")
      .then((response) => response.data),
  getInterventionCount: (
    startDate: Date | undefined,
    granularity: "year" | "month" | "week" | "day",
    projectId: string | undefined,
    dealId: string | undefined,
  ) =>
    axiosClient
      .get<
        {
          key: string;
          values: Record<string, number>;
          startDate: string;
          endDate: string;
        }[]
      >("/interventions/count", {
        params: {
          startDate,
          granularity,
          ...(projectId ? { projectId } : { dealId }),
        },
      })
      .then((response) => response.data),
  getDemandResponseEntityConsumption: (
    id: string,
    params?: { startDate?: string; endDate?: string },
  ) =>
    axiosClient
      .get<{
        lastUpdateDate: string;
        data: {
          day: string;
          data: { timestamp: number; value: number }[];
        }[];
      }>(`/demand-response-entities/${id}/consumption-profile`, {
        params,
      })
      .then((response) => response.data),
  getDeviceHistory: (deviceId: string) =>
    axiosClient
      .get<
        {
          date: string;
          endDate?: string;
          type: string;
          title?: string;
          content?: string;
          url?: string;
        }[]
      >(`/devices/${deviceId}/history`)
      .then((response) => response.data),

  getInstallationAppointmentCommunications: (projectId: string) =>
    axiosClient
      .get<{ emails: string[]; phoneNumbers: string[] }>(
        `/projects/${projectId}/communications-installation-appointment`,
      )
      .then((response) => response.data),

  sendInstallationAppointmentCommunications: (projectId: string) =>
    axiosClient
      .post<void>(
        `/projects/${projectId}/communications-installation-appointment`,
      )
      .then((response) => response.data),
};
